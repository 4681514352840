import React from 'react'

import MusicProjects from '../Data/MusicProjects'

const height_multiply = 0.5
const HomePageSectionMusic = ({width,dimensions,navigate}) => {


	const height = width*height_multiply

	function size_container(component,position) {
		const className = 'overflow ' + position
		return <div style={{height:height,width:width}} className={className}>{component}</div>
	}


	// function albumDisplayer(width,skip) {
	// 	let arr = []
	// 	let devision = 3
	// 	if(width > 600) devision = 4
	// 	if(width > 700) devision = 5
	// 	if(width > 1000) devision = 6
	// 	if(width > 1200) devision = 7
	// 	if(width > 1400) devision = 8
	// 	if(width > 1600) devision = 9
	// 	if(width > 1800) devision = 10
	// 	if(width > 2000) devision = 11
	// 	const high = width / devision
	// 	const length = Object.keys(MusicProjects).length;
	// 	for(const [key, val] of Object.entries(MusicProjects)){
	// 		arr.push(
	// 			<div key={key} style={{width:high,height:high}} className='flexCenter'>
	// 				<div style={{width:high*0.96,height:high*0.96,overflow:'hidden',background:'black'}} className='flexCenter'><img src={val['album_art']} alt='album_art' className='fullWidth' /></div>
	// 			</div>
	// 		)
	// 	}
	// 	return <div className='flexLeft blackBackgroundx' style={{width:high*length}}>{arr}</div>
	// }

	function displayArtists(start,end) {
		let arr = []
		for(const [, val] of Object.entries(MusicProjects)){ arr.push(val) }
		let arr2 = []
		for(let i = start; i < end; i++){
			arr2.push(
				<div key={i} style={{width:width/(end-start),height:height*2,transform:'rotate(20deg)'}} className='flexCenterx overflow relative'>
					<div style={{transform:'rotate(-20deg)'}}>
						<img src={arr[i]['background']} alt='artist_image' className='fullHeightx absolute' style={{marginLeft:-(width*0.3),marginTop:height*.4,height:height*1.2}} />
					</div>
				</div>
			)
		}
		return size_container(arr2,'absolute pointerNone borderRadius overflow flexCenter ggl_border ggl_box_background')
	}


	return(
		<div className='flexCenter' style={{width:dimensions.width,height:height}}>
			{displayArtists(1,6)}
			{size_container(<div style={{height:(height),width:width,
							backgroundImage:'linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.9))'
							}} className='flexCenter flexBottom'>
						<div style={{width:width-40}}>
							<div className='ggl_text_heading ggl_text_main_color textCenterx'><b className='textGradientLighter noBold'>+120M</b> <b className='noBold ggl_textShadow'>streams</b></div>
							{width < 700
								? null
								: <div>
									<div className='ggl_text_secondary_color textCenterx ggl_textShadow'>I have amassed over 120 million streams on songs I have produced</div>
									<div className='space_height_10'></div>
								</div>
							}
							
							<div className='actionColor ggl_text_main_color ggl_text_smaller flexCenter pointer' style={{borderRadius:6,height:30,width:140}} onClick={()=>{navigate('projects')}}>Learn more</div>
							<div className='space_height_20'></div>
							{/*<div className='ggl_text_secondary_color textCenter'>I have built a variety of projects, finding solutions in creative ways, to deliver user friendly applications</div>
							<div className='space_height_40'></div>
							<div className='flexCenter flexRightx'>
								<div className='actionColor ggl_text_main_color ggl_text_smaller flexCenter pointer' style={{borderRadius:6,height:30,width:140}} onClick={()=>{navigate('projects')}}>Learn more</div>
							</div>*/}
						</div>
					</div>,'absolute pointerNonex borderRadius',0)}
		</div>
	)
	// return(
	// 	<div style={{width:dimensions.width}} className='overflow'>
	// 		{albumDisplayer(dimensions.width,0)}
	// 	</div>
	// )
}

export default HomePageSectionMusic