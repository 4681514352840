import React, { useState, useEffect } from 'react'

const RenderSliderImage = ({width,height,data,id,type,text}) => {
	const [ wide, setWide ] = useState(0.5)


	useEffect(()=>{

		function onMousemove(e) {
			// console.log(e)
			// const elem = document.getElementById('container_id_1247f23f23')
			// console.log(elem.getBoundingRect)

			const elem = document.getElementById(id)
			const rect = elem.getBoundingClientRect()
			let percent = (e.clientX - rect.left) / rect.width
			if(percent > 0.995) percent = 0.995
			if(percent < 0.005) percent = 0.005

			setWide(percent)

		}
		// console.log('this ifwe')
		const elem = document.getElementById(id)
		elem.addEventListener('mousemove', onMousemove, false)
	},[id])


	function size_container(component,position,minus) {
		const className = 'overflow ' + position
		return <div style={{height:height-minus,width:width-minus,marginLeft:(minus/2),marginTop:(minus/2)}} className={className}>{component}</div>
	}

	// return(
	// 	<div style={{width:width,height:height}} className='borderRadius ggl_border flexCenter overflow' id={id}>
	// 		{/*{size_container(<img src={data['model']} alt='backgroundImage' className='fullWidth' />,'absolute borderRadius ggl_border borderRadius overflow flexBottom',0)}*/}

	// 		{size_container(<div style={{width:width*wide+160,height:height*0.1,borderRight:'1px solid black'}} className='overflow flexBottom'><img src={data['model']} alt='backgroundImage' style={{width:width}} /></div>,'absolute flexBottom borderRadius',0)}
	// 		{size_container(<div style={{width:width*wide+140,height:height*0.2,borderRight:'1px solid black'}} className='overflow flexBottom'><img src={data['model']} alt='backgroundImage' style={{width:width}} /></div>,'absolute flexBottom borderRadius',0)}
	// 		{size_container(<div style={{width:width*wide+120,height:height*0.3,borderRight:'1px solid black'}} className='overflow flexBottom'><img src={data['model']} alt='backgroundImage' style={{width:width}} /></div>,'absolute flexBottom borderRadius',0)}
	// 		{size_container(<div style={{width:width*wide+100,height:height*0.4,borderRight:'1px solid black'}} className='overflow flexBottom'><img src={data['model']} alt='backgroundImage' style={{width:width}} /></div>,'absolute flexBottom borderRadius',0)}
	// 		{size_container(<div style={{width:width*wide+80,height:height*0.5,borderRight:'1px solid black'}} className='overflow flexBottom'><img src={data['model']} alt='backgroundImage' style={{width:width}} /></div>,'absolute flexBottom borderRadius',0)}
	// 		{size_container(<div style={{width:width*wide+60,height:height*0.6,borderRight:'1px solid black'}} className='overflow flexBottom'><img src={data['model']} alt='backgroundImage' style={{width:width}} /></div>,'absolute flexBottom borderRadius',0)}
	// 		{size_container(<div style={{width:width*wide+40,height:height*0.7,borderRight:'1px solid black'}} className='overflow flexBottom'><img src={data['model']} alt='backgroundImage' style={{width:width}} /></div>,'absolute flexBottom borderRadius',0)}
	// 		{size_container(<div style={{width:width*wide+20,height:height*0.8,borderRight:'1px solid black'}} className='overflow flexBottom'><img src={data['model']} alt='backgroundImage' style={{width:width}} /></div>,'absolute flexBottom borderRadius',0)}

	// 		{size_container(<div style={{width:width*wide,borderRight:'1px solid black',boxShadow:'0px 0px 20px rgba(0,0,0,0.9)'}} className='overflow flexBottom'><img src={data['src']} alt='backgroundImage' style={{width:width}} /></div>,'absolute flexBottom borderRadius',0)}
	// 	</div>
	// )

	if(type === 'left'){
		return(
			<div style={{width:width,height:height}} className='borderRadius ggl_border flexCenter overflow' id={id}>

				{size_container(<div style={{width:width*wide,borderRight:'1px solid black',boxShadow:'0px 0px 20px rgba(0,0,0,0.9)'}} className='overflow flexHeightCenter'><img src={data['model']} alt='backgroundImage' style={{width:width}} /></div>,'absolute flexHeightCenter borderRadius pointerNone',0)}

				{size_container(<img src={data['src']} alt='backgroundImage' className='fullWidth' />,'absolute borderRadius ggl_border borderRadius overflow flexHeightCenter pointerNone',0)}

				{size_container(<div className='hoverOpacityUpx fullHeight fullWidth flexHeightCenter' style={{backgroundImage:'linear-gradient(to right,rgba(0,0,0,.78),rgba(0,0,0,0))',width:width*0.8}}>
						<div style={{width:60}}></div>
						<div style={{width:width*0.24,textShadow:'0px 0px 14px rgba(0,0,0,0.3)',
								// border:'1px solid red'
								// backdropFilter:'blur(2px)',boxShadow:'0px 0px 16px rgba(0,0,0,0.4)'
								}} className='borderRadius ggl_borderx'>
							<div className='ggl_text_heading ggl_text_main_color textCenter'><b className='textGradientLighter'>{text['heading']}</b> Renders</div>
							{/*<div style={{height:20}}></div>*/}
							<div className='ggl_text_main_color textCenter ggl_text_main_secondary'>{text['text']}</div>
							{/*<div style={{height:40}}></div>
							<div className='flexCenter flexRightx'>
								<div className='actionColor ggl_text_main_color ggl_text_smaller flexCenter pointer' style={{borderRadius:6,height:30,width:140}}>Learn more</div>
							</div>*/}
						</div>

					</div>,'absolute borderRadius ggl_border borderRadius overflow flexHeightCenter',0)}

				
			</div>
		)
	} else if(type === 'right'){
		return(
			<div style={{width:width,height:height}} className='borderRadius ggl_border flexCenter overflow' id={id}>

				{size_container(<div style={{width:width*wide,borderRight:'1px solid black',boxShadow:'0px 0px 20px rgba(0,0,0,0.9)'}} className='overflow flexHeightCenter'><img src={data['model']} alt='backgroundImage' style={{width:width}} /></div>,'absolute flexHeightCenter borderRadius pointerNone',0)}

				{size_container(<img src={data['src']} alt='backgroundImage' className='fullWidth' />,'absolute borderRadius ggl_border borderRadius overflow flexHeightCenter pointerNone',0)}

				{size_container(<div className='hoverOpacityUpx fullHeight fullWidth flexHeightCenter flexRight' style={{backgroundImage:'linear-gradient(to left,rgba(0,0,0,.78),rgba(0,0,0,0))',width:width*0.8}}>

						<div style={{width:width*0.24,textShadow:'0px 0px 14px rgba(0,0,0,0.3)',
								// border:'1px solid red'
								// backdropFilter:'blur(2px)',boxShadow:'0px 0px 16px rgba(0,0,0,0.4)'
								}} className='borderRadius ggl_borderx'>
							<div className='ggl_text_heading ggl_text_main_color textCenter'><b className='textGradientLighter'>{text['heading']}</b> Renders</div>
							<div style={{height:20}}></div>
							<div className='ggl_text_main_color textCenter ggl_text_main_secondary'>{text['text']}</div>
						</div>
						<div style={{width:60}}></div>
					</div>,'absolute borderRadius ggl_border borderRadius overflow flexHeightCenter flexRight',0)}

				
			</div>
		)
	}
	return(
		<div style={{width:width,height:height}} className='borderRadius ggl_border flexCenter overflow pointer' id={id}>
			{size_container(<img src={data['model']} alt='backgroundImage' className='fullWidth' />,'absolute borderRadius ggl_border borderRadius overflow flexHeightCenter pointerNone',0)}

			{size_container(<div className='hoverOpacityUpx fullHeight fullWidth flexCenter' style={{background:'rgba(0,0,0,.7)'}}>
					<div style={{width:width*0.94
							}} className='borderRadius ggl_borderx flexRight'>
						<div className='ggl_text_heading ggl_text_main_color textCenter bold'>is to <b className='textGradientLighter'>believe</b> it</div>
					</div>
				</div>,'absolute borderRadius ggl_border borderRadius overflow flexHeightCenter',0)}

			{size_container(<div style={{width:width*wide,borderRight:'1px solid black',boxShadow:'0px 0px 20px rgba(0,0,0,0.9)'}} className='overflow flexHeightCenter'><img src={data['src']} alt='backgroundImage' style={{width:width}} /></div>,'absolute flexHeightCenter borderRadius pointerNone',0)}

			{size_container(<div style={{width:width*wide,height:height}} className='overflow flexHeightCenter relative'>
					<div className='ggl_text_heading ggl_text_main_color textCenterx bold absolute flexLeft' style={{width:width*0.94,textShadow:'0px 0px 14px rgba(0,0,0,.3)'}}>
						<div style={{width:width*0.03}}></div>
						<div>To see it...</div>
					</div>
				</div>,'absolute flexHeightCenter borderRadius pointerNone',0)}

		</div>
	)
}

export default RenderSliderImage