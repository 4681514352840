import React from 'react'

import MusicProjects from '../Data/MusicProjects'
import ProgramCircleDisplayer from '../Portfolio/ProgramCircleDisplayer'

// import tech_example_1 from '../../Images/tech_example_1.jpeg'

const MusicAllProjectDisplay = ({width,height,padding,navigate}) => {

	function size_container(component,position,minus) {
		const className = 'overflow ' + position
		return <div style={{height:height-minus,width:width-minus,marginLeft:(minus/2),marginTop:(minus/2)}} className={className}>{component}</div>
	}

	function albumDisplayer(wide,skip) {
		let arr = []
		// const high = 300
		for(const [, val] of Object.entries(MusicProjects)){
			arr.push(val)
		}
		let arr2 = []
		for(let i = 0; i < arr.length; i++){
			arr2.push(
				<div key={i} style={{width:wide,height:wide}} className='flexCenter'>
					<div style={{width:wide-(wide*0.04),height:wide-(wide*0.04),overflow:'hidden',borderRadius:6}} className='flexCenter blackBackground'><img src={arr[(i+skip)%arr.length]['album_art']} alt='album_art' className='fullWidth' /></div>
				</div>
			)
		}
		return <div className='flexLeft blackBackgroundx' style={{flexWrap:'wrap',width:wide*4}}>{arr2}</div>
	}
	// function albumDisplayer2(wide,skip) {
	// 	let arr = []
	// 	const high = 300
	// 	for(const [key, val] of Object.entries(MusicProjects)){
	// 		arr.push(val)
	// 	}
	// 	let arr2 = []
	// 	for(let i = 0; i < arr.length; i++){
	// 		arr2.push(
	// 			<div key={i} style={{width:wide,height:wide}} className='flexCenter'>
	// 				<div style={{width:wide-(wide*0.04),height:wide-(wide*0.04),overflow:'hidden',borderRadius:6}} className='flexCenterx blackBackground'><img src={arr[(i+skip)%arr.length]['background']} alt='album_art' className='fullHeight' /></div>
	// 			</div>
	// 		)
	// 	}
	// 	return <div className='flexLeft blackBackgroundx' style={{flexWrap:'wrap',width:wide*4,background:'rgb(40,40,40)'}}>{arr2}</div>
	// }
	if(width < 1200){
		return(
			<div style={{width:width,height:height}} className='overflow relative ggl_border borderRadius'>
				{size_container(<div className='fullHeight fullWidth ggl_box_background'></div>,'absolute pointerNone',0)}

				{size_container(<div className='flexLeft textCenter'>
					<div style={{height:(height),width:width}} className='flexCenter'>
						<div style={{height:(height),width:width,filter:'blur(2px)'}} className='absolute ggl_borderx borderRadiusx overflow flexCenter'>
							{albumDisplayer(width/4,0)}
						</div>
					</div>
				</div>,'absolute pointerNone',0)}

				{size_container(<div className='fullHeight fullWidth flexCenter' style={{backgroundImage:'radial-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.6))'}}>
						<div style={{width:width*0.8}}>
							<div className='ggl_text_heading ggl_text_main_color textCenter'><b className='textGradientLighter'>+120</b> million streams</div>
							<div className='space_height_10'></div>
							<div className='ggl_text_secondary_color textCenter'>I have produced music for a number of artists and collaborated with some high profile writers and producers</div>
							<div className='space_height_20'></div>
							<div className='flexCenter flexRightx'>
								<div className='actionColor ggl_text_main_color ggl_text_smaller flexCenter pointer' style={{borderRadius:6,height:30,width:140}} onClick={()=>{navigate('projects')}}>Learn more</div>
							</div>
						</div>
					</div>,'absolute pointerNonex',0)}

			</div>
		)
	}
	return(
		<div style={{width:width,height:height}} className='overflow relative ggl_border borderRadius'>
			{size_container(<div className='fullHeight fullWidth ggl_box_background'></div>,'absolute pointerNone',0)}
			{/*{size_container(<img src={MusicProjects['aboutyou']['background']} alt='backgroundImage' style={{width:width,filter:'blur(0px)'}} />,'absolute flexCenter',0)}*/}
			{/*{size_container(<div className='fullHeight fullWidth' style={{backgroundImage:'linear-gradient(to left,rgba(0,0,0,0.4),rgba(0,0,0,0))'}}></div>,'absolute pointerNone',0)}*/}
			{/*{size_container(albumDisplayer(width/6,0),'absolute flexRight',0)}*/}

			{size_container(<div className='flexLeft textCenter'>
				<div style={{height:(height),width:width-((height)*1.312)}} className='flexCenter'>
					<div style={{height:(height-padding),width:(width-(height)*1.312)-padding}} className='absolute ggl_borderx borderRadius overflow flexCenter'>
						<div>
							<div className='ggl_text_heading ggl_text_main_color textCenter'><b className='textGradientLighter'>+120</b> million streams</div>
							<div style={{height:20}}></div>
							<div className='ggl_text_secondary_color textCenter'>I have produced music for a number of artists and collaborated with some high profile writers and producers</div>
							<div style={{height:40}}></div>
							<div className='flexCenter flexRightx'>
								<div className='actionColor ggl_text_main_color ggl_text_smaller flexCenter pointer' style={{borderRadius:6,height:30,width:140}} onClick={()=>{navigate('projects')}}>Learn more</div>
							</div>
						</div>
					</div>
				</div>
				<div style={{height:(height),width:(height)*1.312}} className='flexCenter'>
					<div style={{height:(height-padding),width:(height-padding)*1.312}} className='absolute ggl_borderx borderRadius overflow flexCenter'>
						{albumDisplayer((height-padding)*1.312/4.1,0)}
					</div>
				</div>
			</div>,'absolute pointerNonex',0)}

			{size_container(<div style={{padding:30}}><ProgramCircleDisplayer width={width} dimensions={{height:40,width:500}} type={{'current':1,}} /></div>,'absolute flexBottom pointerNone',0)}
		</div>
	)
}

export default MusicAllProjectDisplay