import React from 'react'

// import mobile from '../../Images/mobile_screenshot.jpg'
// import code_example from '../../Images/code_example.png'
// import comp_example1 from '../../Images/comp_example1.jpeg'
import tech_example_1 from '../../Images/tech_example_1.jpeg'

const height_multiply = 0.5

const EducationDisplayComponent = ({data,width}) => {

	function goToLink() {
		window.open(data['link'], "_blank")
	}


	const height = width*height_multiply

	function size_container(component,position) {
		const className = 'overflow ' + position
		return <div style={{height:height,width:width}} className={className}>{component}</div>
	}


	return(
		<div className='fadeIn hoverOpacityDownx pointer relative' style={{overflow:'hidden',height:height,width:width}} onClick={goToLink}>

			{size_container(<img src={tech_example_1} alt='backgroundImage' style={{width:width,filter:'blur(4px)'}} />,'absolute flexCenter')}
			{size_container(<div className='fullHeight fullWidth' style={{backgroundImage:'linear-gradient(to right,rgba(0,0,0,0.8),rgba(0,0,0,0))'}}></div>,'absolute pointerNone')}
			
			{size_container(
				<div style={{backgroundImage:'linear-gradient(20deg,rgba(0,0,0,0.7),rgba(0,0,0,0))',height:height}} className='fullWidth fullHeight flexBottom'>
					<div style={{width:width*0.8,padding:height*0.05}} className='textShadowx'>
						<div className='bold text_bigger uppercase whiteColor' style={{textShadow:'0px 0px 20px black'}}>{data['heading']}</div>
						<div style={{marginTop:10}} className='text_heading whiteColor'>{data['name']}</div>
					</div>
				</div>
			,'absolute flexBottom pointerNone')}

			{size_container(
				<div className='flexRight fullWidth fullHeight'>
					<div style={{height:(height*0.8),width:(height*0.8)*1.35,marginRight:10,marginTop:10,borderRadius:8,
							boxShadow:'0px 0px 12px rgba(0,0,0,0.5)',background:'white',
							// background:'rgb(40,40,40)',border:'3px solid rgb(40,40,40)'
							}} className='flexCenter absolute overflow'>
						<img src={data['link']} alt='link' className='fullWidth' />
					</div>
				</div>
			,'absolute pointerNone')}

			{/*{size_container(
				<div className='flexRight flexCenterx fullWidth fullHeight'>
					<div style={{width:(height*0.88)*0.565*0.82 + 1,height:(height*0.88),marginTop:(height*0.88)*0.09,borderRadius:28,
							boxShadow:'0px 0px 12px rgba(0,0,0,0.5)',background:'rgb(40,40,40)',border:'4px solid rgb(40,40,40)'
							}} className='flexCenter relative overflow'>
						<img src={data['mobile']} alt='mobile' className='fullWidth' />
					</div>
					<div style={{width:(height*0.88)*0.02}}></div>
				</div>
			,'relative pointerNone')}*/}

		</div>
	)
	// return(
	// 	<div style={{width:width}} className='text_main'>
	// 		<div>{data['name']}</div>
	// 		<div>{data['company']}</div>
	// 	</div>
	// )
}

export default EducationDisplayComponent