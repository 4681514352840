import React from 'react'

const skills = {
	'react': {'name':'React','skill':1.0,'passion':0.7,'experience':1.0},
	'react native': {'name':'React Native','skill':0.8,'passion':0.6,'experience':0.7},
	'node': {'name':'Node','skill':0.6,'passion':0.4,'experience':0.5},
	'python': {'name':'Python','skill':0.8,'passion':0.7,'experience':0.7},
	'ai': {'name':'A.I.','skill':0.7,'passion':0.9,'experience':0.4},
	'music': {'name':'Music production','skill':1.0,'passion':1.0,'experience':1.0},
	'blender': {'name':'3D renders','skill':0.6,'passion':0.7,'experience':0.5},
}

// const container_height = 400
// const container_width = 600

// let rgb_color_array = [60,20,150]  // this will be a prop

const SkillGraph = ({type,rgb_color_array,height,width,num_to_key,setTopic,topic}) => {

	const rgbs = rgb_color_array

	function skillsDisplay() {
		let arr = []
		const col_wide = width / Object.keys(skills).length
		let col_padding = 30
		if(width < 600) col_padding = 20
		if(width < 450) col_padding = 10
		Object.keys(skills).forEach(function(key,item){

			let percentage = skills[key][num_to_key[type['current']]]
			if(type['count'] < 10){
				const prev_num = skills[key][num_to_key[type['prev']]]
				const curr_num = skills[key][num_to_key[type['current']]]
				const one_unit = Math.abs(prev_num - curr_num) / 10
				if(prev_num < curr_num){
					percentage = prev_num + (one_unit*type['count'])
				} else {
					percentage = prev_num - (one_unit*type['count'])
				}
			}
			let className = 'fullWidth textCenter uppercasex ggl_text_main_secondary fadeInslow'
			if(topic === key) className = 'bold fullWidth textCenter uppercasex ggl_text_main_secondary fadeInslow'
			arr.push(<div key={key} className='fullWidth flexHeightCenter fadeIn' style={{height:col_wide}}>
				<div style={{height:col_wide-col_padding,width:`${percentage*100}%`}}>
					<div className='leftToRightLeft flexCenter fullHeight pointer hoverOpacityDown' onClick={()=>{setTopic(key)}}
							style={{border:`1px solid rgba(${rgbs[0]},${rgbs[1]},${rgbs[2]},0.1)`,
							borderRadius:6,
							backgroundImage:`linear-gradient(to right,rgba(${rgbs[0]},${rgbs[1]},${rgbs[2]},0.5),rgba(${rgbs[0]},${rgbs[1]},${rgbs[2]},0.${topic === key ? 6 : 2}))`}}>
						<div className={className}
								// style={{color:`rgb(${rgbs[0]},${rgbs[1]},${rgbs[2]})`,textShadow:'0px 0px 5px white',pointerEvents:'none'}}
								style={{color:'white'}}>{skills[key]['name']}</div>
					</div>
					<div className='leftToRightRight'></div>
				</div>
			</div>)
		})
		return arr
	}
	return(
		<div className='flexCenter text_main' style={{height:height}}>
			<div 
					style={{width:height,height:width,transform:'rotate(-90deg)',
					// borderLeft:`1px solid rgba(${rgbs[0]},${rgbs[1]},${rgbs[2]},0.1)`,borderTop:`1px solid rgba(${rgbs[0]},${rgbs[1]},${rgbs[2]},0.3)`,
					// borderRight:`1px solid rgba(${rgbs[0]},${rgbs[1]},${rgbs[2]},0.3)`,borderBottom:`1px solid rgba(${rgbs[0]},${rgbs[1]},${rgbs[2]},0.1)`,
					// backgroundImage:`linear-gradient(45deg,rgba(${rgbs[0]},${rgbs[1]},${rgbs[2]},0.05),rgba(${rgbs[0]},${rgbs[1]},${rgbs[2]},0))`,
					// borderRadius:12,
					// padding:20
					}}
					// className='ggl_border borderRadius ggl_box_background'
					>
				{skillsDisplay()}
			</div>
		</div>
	)
}

export default SkillGraph