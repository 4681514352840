import React from 'react'

import MusicProjects from '../Data/MusicProjects'
// import RenderProject from '../Data/RenderProject'
// import Portfolio from '../Data/Portfolio'

// import fork_and_spoon_mobile from '../../Images/screen_shots/fork_and_spoon_mobile.png'
// import mac_example from '../../Images/mac_example.jpeg'
// import mac_example2 from '../../Images/mac_example2.jpg'
// import dev_example from '../../Images/dev_example.jpg'
// import dev_example2 from '../../Images/dev_example2.jpeg'
import tech_example_1 from '../../Images/tech_example_1.jpeg'
import render_banner_pic_1 from '../../Images/render_banner_pic_1.jpeg'
// import render_banner_pic_2 from '../../Images/render_banner_pic_2.jpeg'
// import render_banner_pic_3 from '../../Images/render_banner_pic_3.jpeg'
// import render_banner_pic_4 from '../../Images/render_banner_pic_4.jpeg'

// const colors = [
// 	[[170,150,180],[120,80,210]],
// 	[[170,80,120],[170,10,80]],
// 	[[170,80,210],[30,10,80]],
// ]
// const blurDegree = 6

const ProjectBanner = ({width,height,type,changeType}) => {




	// function getColorPoint(prev,curr,step,c,p) {
	// 	if(c < p){
	// 		if(prev < curr){
	// 			return prev + Math.round((curr-prev) * (step/10))
	// 		} else {
	// 			return curr + Math.round((prev-curr) * (step/10))
	// 		}
	// 	} else {
	// 		if(curr < prev){
	// 			return curr + Math.round((prev-curr) * ((10-step)/10))
	// 		} else {
	// 			return prev + Math.round((curr-prev) * ((10-step)/10))
	// 		}
	// 	}
	// }



	let extra_width = 200
	if(width < 1200){
		extra_width = width / 6
	}
	let wide1 = width/3 + extra_width
	let wide2 = width/3 - (extra_width/2)
	let wide3 = width/3 - (extra_width/2)

	if(type['count'] < 10){
		const from = width/3 + extra_width
		const to = width/3 - (extra_width/2)
		if(type['prev'] === 0) wide1 = from - ((from - to) * type['count']/10)
		if(type['current'] === 0) wide1 = from - ((from - to) * (10-type['count'])/10)
		if(type['current'] !== 0 && type['prev'] !== 0) wide1 = to

		if(type['prev'] === 1) wide2 = from - ((from - to) * type['count']/10)
		if(type['current'] === 1) wide2 = from - ((from - to) * (10-type['count'])/10)

		if(type['prev'] === 2) wide3 = from - ((from - to) * type['count']/10)
		if(type['current'] === 2) wide3 = from - ((from - to) * (10-type['count'])/10)
	} else {
		if(type['current'] === 0){
			wide1 = width/3 + extra_width
			wide2 = width/3 - (extra_width/2)
			wide3 = width/3 - (extra_width/2)
		}
		if(type['current'] === 1){
			wide2 = width/3 + extra_width
			wide1 = width/3 - (extra_width/2)
			wide3 = width/3 - (extra_width/2)
		}
		if(type['current'] === 2){
			wide3 = width/3 + extra_width
			wide1 = width/3 - (extra_width/2)
			wide2 = width/3 - (extra_width/2)
		}
	}



	function albumDisplayer(wide,skip) {
		let arr = []
		// const high = 300
		for(const [, val] of Object.entries(MusicProjects)){
			arr.push(val)
		}
		let arr2 = []
		for(let i = 0; i < arr.length; i++){
			arr2.push(
				<div key={i} style={{width:wide,height:wide}} className='flexCenter'>
					<div style={{width:wide-(wide*0.04),height:wide-(wide*0.04),overflow:'hidden',borderRadius:6}} className='flexCenter blackBackground'><img src={arr[(i+skip)%arr.length].album_art} alt='album_art' className='fullWidth' /></div>
				</div>
			)
		}
		return <div className='flexLeft blackBackgroundx' style={{flexWrap:'wrap',width:wide*4,background:'rgb(40,40,40)'}}>{arr2}</div>
	}
	function renderDisplay() {
		return <div style={{height:height}} className='flexBottomx flexCenter'>
			{/*<img src={RenderProject['exterior_render_1']['src']} alt='archvis' className='fullWidth' style={{width:'150%'}} />*/}
			<img src={render_banner_pic_1} alt='archvis' className='fullWidth' style={{width:'170%'}} />
		</div>
	}
	function devDisplay() {
		return <div style={{height:height}} className='flexCenter flexTopx'>
			{/*<img src={Portfolio['tender']['desktop']} alt='desktop' className='fullHeight' />*/}
			<img src={tech_example_1} alt='fork_and_spoon_mobile' className='fullWidth' style={{width:'140%'}} />
		</div>
	}


	return(
		<div style={{width:width,height:height,zIndex:0}}>
			<div style={{width:width,height:height,zIndex:0}} className='flexLeft overflow relativex blackBackground absolute'>
				<div style={{height:height*2,marginTop:-height*0.5,width:wide1,transform:'rotate(20deg)',border:'0px solid white',zIndex:0}} className='flexCenter overflowx blackBackground relative'>
					<div style={{transform:'rotate(-20deg)',opacity:type['current'] === 0 ? 1 : 0.2}}>{devDisplay()}</div>
				</div>
				<div style={{height:height*2,marginTop:-height*0.5,width:wide2,transform:'rotate(20deg)',border:'0px solid white',zIndex:1,}} className='flexCenter overflow blackBackground relative'>
					<div style={{transform:'rotate(-20deg)',opacity:type['current'] === 1 ? 1 : 0.2}} className='flexLeftx'>
						{albumDisplayer(wide2/3,2)}
						{albumDisplayer(wide2/3,0)}
						{albumDisplayer(wide2/3,6)}
					</div>
				</div>
				<div style={{height:height*2,marginTop:-height*0.5,width:wide3,transform:'rotate(20deg)',border:'0px solid white',zIndex:0}} className='flexCenter overflowx blackBackground relative'>
					<div style={{transform:'rotate(-20deg)',opacity:type['current'] === 2 ? 1 : 0.4}}>{renderDisplay(width/3)}</div>
				</div>
			</div>

			<div style={{width:width,height:height,zIndex:0}} className='flexCenter overflow absolute ggl_text_heading ggl_text_main_color'>
				<div style={{height:height,textShadow:'0px 0px 14px rgba(0,0,0,1)',
						// background:'rgba(0,0,0,0)',backdropFilter:'blur(12px)'
						}} className='fullWidth flexCenter'>
					<div style={{width:wide1,backgroundImage:'radial-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0))'}} className='flexCenter fullHeight uppercase bold'>
						<div style={{opacity:type['current'] === 0 ? 1 : 1}}>{"</>"} Dev</div>
					</div>
					<div style={{width:wide2,backgroundImage:'radial-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0))'}} className='flexCenter fullHeight uppercase bold'>
						<div style={{opacity:type['current'] === 1 ? 1 : 1}}>Music</div>
					</div>
					<div style={{width:wide3,backgroundImage:'radial-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0))'}} className='flexCenter fullHeight uppercase bold'>
						<div style={{opacity:type['current'] === 2 ? 1 : 1}}>Rendering</div>
					</div>
				</div>
			</div>

			<div style={{width:width,height:height,zIndex:0}} className='flexLeft overflow relativex absolute'>
				<div style={{height:height*2,marginTop:-height*0.5,width:wide1,transform:'rotate(20deg)',border:'0px solid white',zIndex:0}} className='flexCenter relative pointer' onClick={()=>{changeType(0)}}></div>
				<div style={{height:height*2,marginTop:-height*0.5,width:wide2,transform:'rotate(20deg)',border:'2px solid white',zIndex:1}} className='flexCente relative pointer' onClick={()=>{changeType(1)}}></div>
				<div style={{height:height*2,marginTop:-height*0.5,width:wide3,transform:'rotate(20deg)',border:'0px solid white',zIndex:0}} className='flexCenter relative pointer' onClick={()=>{changeType(2)}}></div>
			</div>
		</div>
	)
}

export default ProjectBanner