import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'

const wideLimit = 700

const Navbar = ({route,dimensions,height,navigate}) => {
	const [ expanded, setExpanded ] = useState(false)

	// Modal stuff
		const node = useRef();
		useEffect(()=>{
			function handleClick(e) {
				if(node.current) {
					if(node.current.contains(e.target)){
					} else {
						setExpanded(false)
					}
				}
			}
			document.addEventListener('mousedown', handleClick, false);
			return () => {
				document.removeEventListener('mousedown', handleClick, false);
			}
		},[]);
		useEffect(()=>{
			if(dimensions.width >= wideLimit) setExpanded(false)
		},[dimensions])


	function routeClicked(e) {
		if(route !== e){
			navigate(e)
		}
		setExpanded(false)
	}




	function itemDisplay(text,rte,wideLim) {
		if(dimensions.width < wideLim) return null
		let item_class = ' ggl_text_main_smaller ggl_text_main_color pointer hoverOpacityUp'
		if((route.includes(rte) && rte !== '') || (rte === route)) item_class = 'boldx ggl_text_main_smaller ggl_text_main_color pointer'
		return <div className={item_class} style={{padding:10}} onClick={()=>{routeClicked(rte)}}>{text}</div>
	}
	function burgerDisplay(wideLim) {
		if(dimensions.width >= wideLim) return null
		if(expanded === true){
			return(
				<div style={{padding:10}} onClick={()=>{setExpanded(!expanded)}}>
					<div className='navHamburger'>
						<div className='navBarTopHamburgerToCross navHamburger whiteBackground absolute'></div>
					</div>
					<div className='navHamburgerSpace'></div>
					<div className='navBarMiddleHamburgerToCross navHamburger whiteBackground'></div>
					<div className='navHamburgerSpace'></div>
					<div className='navHamburger'>
						<div className='navBarBottomHamburgerToCross navHamburger whiteBackground absolute'></div>
					</div>
				</div>
			)
		}
		return(
			<div style={{padding:10}} onClick={()=>{setExpanded(!expanded)}}>
				<div className='navHamburger'>
						<div className='navBarTopHamburgerFromCross navHamburger whiteBackground absolute'></div>
					</div>
					<div className='navHamburgerSpace'></div>
					<div className='navBarMiddleHamburgerFromCross navHamburger whiteBackground'></div>
					<div className='navHamburgerSpace'></div>
					<div className='navHamburger'>
						<div className='navBarBottomHamburgerFromCross navHamburger whiteBackground absolute'></div>
					</div>
			</div>
		)
	}
	function expandedBody(wideLim) {
		if(expanded === false) return null
		return(
			<div style={{position:'fixed',right:0,top:height,bottom:0}}>
				<div style={{background:'black',height:'100%',width:300,boxShadow:'0px 0px 14px rgba(0,0,0,0.5)'}}>
					{itemDisplay('Home','',10)}
					{/*{itemDisplay('About','about',10)}*/}
					{itemDisplay('Contact','contact',10)}
					{itemDisplay('Portfolio','projects',10)}
					{itemDisplay('Education','education',10)}
				</div>
			</div>
		)
	}
	return(
		<div ref={node} className='relative' style={{zIndex:2}}>
			<div className='flexHeightCenter navBar' style={{height:height,position:'fixed',top:0,left:0,right:0,color:'white',zIndex:10,boxShadow:'0px 0px 14px rgba(0,0,0,0.5)'}}>
				<div className='flexHeightCenter' style={{width:'70%'}}>
					{itemDisplay('Home','',10)}
					{/*{itemDisplay('About','about',wideLimit)}*/}
					{itemDisplay('Portfolio','projects',wideLimit)}
					{itemDisplay('Contact','contact',wideLimit)}
					{itemDisplay('Education','education',wideLimit)}
				</div>
				<div className='flexRight' style={{width:'30%'}}>
					{burgerDisplay(wideLimit)}
				</div>
			</div>
			{expandedBody(wideLimit)}
		</div>
	)
}

// export default Navbar

const mapStateToProps = (state) => ({
	dimensions: state.dimensions.dimensions
})
const mapDispatchToProps = (dispatch) => ({
	// increment: () => dispatch({type: 'INCREMENT' }),
	// decrement: () => dispatch({type: 'DECREMENT' })
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)