import React from 'react'
import { connect } from 'react-redux'

const AudioPlaybackFunction = ({selected_track,play,playClicked,setAudioTime,audio_seek}) => {

	const audio = React.useRef()
	const time_keep_interval = React.useRef()
	React.useEffect(()=>{
		if(selected_track['audio_path'] !== undefined){
			if(audio.current !== undefined){
				if(selected_track['audio_path'] !== audio.current['audio_path']){
					audio.current['audio'].pause()
					console.log('ARE WE STOPPING?')
				}
			}
			const audio_ = new Audio(selected_track['audio_path'])
			audio.current = {'audio':audio_,'audio_path':selected_track['audio_path']}
			playClicked()
		}
	},[selected_track,playClicked])

	React.useEffect(()=>{
		if(audio.current !== undefined){
			if(play === false){
				audio.current['audio'].pause()
				time_keep_interval.current = undefined
			} else {
				audio.current['audio'].play()

				setAudioTime(audio.current['audio'].currentTime)
				time_keep_interval.current = setInterval(function() {
					setAudioTime(audio.current['audio'].currentTime)
				}, 500);

			}
		}
	},[play,setAudioTime])

	React.useEffect(()=>{
		if(audio.current !== undefined && audio_seek !== null){
			audio.current['audio'].currentTime = audio_seek
			setAudioTime(audio.current.currentTime)
		}
	},[audio_seek,setAudioTime])

	return null
}

// export default AudioPlaybackFunction

const mapStateToProps = (state) => ({
	selected_track: state.handlePlayback.selected_track,
	play: state.handlePlayback.play,
	audio_seek: state.handlePlayback.audio_seek,
})
const mapDispatchToProps = (dispatch) => ({
	playClicked: () => dispatch({type: 'PLAY_CLICKED'}),
	setAudioTime: (e) => dispatch({type: 'SET_AUDIO_TIME',payload: e}),
})

export default connect(mapStateToProps, mapDispatchToProps)(AudioPlaybackFunction)