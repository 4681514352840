import React from 'react'

const WaveVisualizerLight = ({width,height,selected,time_keeper,setAudioPlaybackPoint}) => {


	function portionClicked(e) {
		const percent = e / selected['wave_shape'].length
		setAudioPlaybackPoint(selected['seconds']*percent)
	}

	function loadingDisplay(high,wide,itt,wave_form) {
		let current_seconds = time_keeper / selected['seconds']
		if(itt <= current_seconds*wave_form.length && time_keeper !== 0){
			return <div style={{height:high,width:wide,boxShadow:'0px 0px 5px rgba(0,0,0,0)',background:'red'}} className='large_border_radius background_gradient_up_down'></div>
		} else {
			return <div style={{height:high,width:wide,boxShadow:'0px 0px 5px rgba(0,0,0,0)',background:'white'}} className='large_border_radius wavformLightColor'></div>
		}
	}
	function visualDisplay2() {
		if(width === 0) return null
		let arr = []
		let wave_wide = 6
		const wave_form = selected['wave_shape']
		let length = Math.floor(width / wave_wide)
		for(let i = 0; i < length; i++){
			let closest_itt = Math.floor((wave_form.length * (i/length)))
			let high = wave_form[ closest_itt ] * (height) + 2
			arr.push(
				<div key={i} style={{width:wave_wide,height:height}} className='pointer hoverBubble flexCenter' onClick={()=>{portionClicked(closest_itt)}}>
					{loadingDisplay(high,wave_wide*0.2,closest_itt,wave_form)}
				</div>
			)
		}
		return(
			<div className='flexHeightCenter'>{arr}</div>
		)
	}

	return visualDisplay2()
}

export default WaveVisualizerLight