import React from 'react'

import Portfolio from '../Data/Portfolio'
import ProgramCircleDisplayer from './ProgramCircleDisplayer'

// import tech_example_1 from '../../Images/tech_example_1.jpeg'

const DevelopementAllProjectDisplay = ({width,height,padding,navigate}) => {

	function size_container(component,position,minus) {
		const className = 'overflow ' + position
		return <div style={{height:height-minus,width:width-minus,marginLeft:(minus/2),marginTop:(minus/2)}} className={className}>{component}</div>
	}

	// function projectLoop() {
	// 	let arr = []
	// 	const ob_length = Object.keys(Portfolio).length
	// 	let count = 0
	// 	for(const [key, val] of Object.entries(Portfolio)){
	// 		arr.push(
	// 			<div key={key} style={{height:(height*0.8),width:(height*0.8)*1.312,overflow:'hidden',borderRadius:6,zIndex:ob_length-count,marginLeft:(count)*(height*0.05),marginTop:(ob_length-count)*(height*0.02),boxShadow:'0px 0px 12px rgba(0,0,0,0.8)',background:'black',border:'1px solid black'}} className='absolute'>
	// 				<img src={val['desktop']} alt='desktopImage' className='fullWidth' />
	// 			</div>
	// 		)
	// 		count+=1
	// 	}
	// 	return <div className='flexRightx' style={{marginLeft:height*0.56}}>{arr}</div>
	// }

	if(width < 1200){
		return(
			<div style={{width:width,height:height}} className='overflow relative ggl_border borderRadius fadeInslow'>
				{size_container(<div className='fullHeight fullWidth ggl_box_background'></div>,'absolute pointerNone borderRadius',0)}

				{/*{size_container(<img src={tech_example_1} alt='desktopImage' className='fullWidth' style={{filter:'blur(8px)'}} />,'absolute pointerNone flexCenter borderRadius',0)}*/}

				{size_container(<div style={{height:(height),width:width,
							// backgroundImage:'linear-gradient(rgba(0,0,0,0.5),black)'
							}} className='flexCenter'>
						<div style={{width:width*0.8}}>
							<div className='ggl_text_heading ggl_text_main_color textCenter'><b className='noBold ggl_textShadow'>Built with</b> <b className='textGradientLighter noBold'>code.</b></div>
							{/*<div className='ggl_text_heading ggl_text_main_color textCenter'><a className='textGradient'>Designed</a> for users.</div>*/}
							<div className='space_height_10'></div>
							<div className='ggl_text_secondary_color textCenter ggl_textShadow'>I have built a variety of projects, finding solutions in creative ways, to deliver user friendly applications</div>
							<div className='space_height_40'></div>
							<div className='flexCenter flexRightx'>
								<div className='actionColor ggl_text_main_color ggl_text_smaller flexCenter pointer' style={{borderRadius:6,height:30,width:140}} onClick={()=>{navigate('projects')}}>Learn more</div>
							</div>
						</div>
					</div>,'absolute pointerNonex borderRadius',0)}

				{/*{size_container(<div style={{padding:30}}><ProgramCircleDisplayer width={width} dimensions={{height:40,width:500}} type={{'current':0,}} /></div>,'absolute flexBottom pointerNone',0)}*/}
			</div>
		)
	}
	return(
		<div style={{width:width,height:height}} className='overflow relative ggl_border borderRadius fadeInslow'>
			{size_container(<div className='fullHeight fullWidth ggl_box_background'></div>,'absolute pointerNone',0)}

			{size_container(<div className='flexLeft'>
					<div style={{height:(height),width:(height)*1.312}} className='flexCenter'>
						<div style={{height:(height-padding),width:(height-padding)*1.312}} className='absolute ggl_borderx borderRadius overflow'>
							<img src={Portfolio['songhatch']['desktop']} alt='desktopImage' className='fullWidth' />
						</div>
					</div>
					<div style={{height:(height),width:width-((height)*1.312)}} className='flexCenter'>
						<div style={{height:(height-padding),width:(width-(height)*1.312)-padding}} className='absolute ggl_borderx borderRadius overflow flexCenter'>
							<div style={{width:(width-(height)*1.312)-padding-20}}>
								<div className='ggl_text_heading ggl_text_main_color textCenter'>Built in <b className='textGradientLighter noBold'>code.</b></div>
								<div className='ggl_text_heading ggl_text_main_color textCenter'><b className='textGradientLighter noBold'>Designed</b> for users.</div>
								<div style={{height:10}}></div>
								<div className='ggl_text_secondary_color textCenter'>I have built a variety of projects, finding solutions in creative ways, to deliver user friendly applications</div>
								<div style={{height:40}}></div>
								<div className='flexCenter flexRightx'>
									<div className='actionColor ggl_text_main_color ggl_text_smaller flexCenter pointer' style={{borderRadius:6,height:30,width:140}} onClick={()=>{navigate('projects')}}>Learn more</div>
								</div>
							</div>
						</div>
					</div>
				</div>,'absolute pointerNonex',0)}

			{size_container(<div style={{padding:30}}><ProgramCircleDisplayer width={width} dimensions={{height:40,width:500}} type={{'current':0,}} /></div>,'absolute flexBottom pointerNone',0)}
		</div>
	)
	// return(
	// 	<div style={{width:width,height:height,boxShadow:'0px 0px 16px black',border:'0px solid rgba(220,200,255,0.5)'}}>
			

	// 		{size_container(<img src={tech_example_1} alt='backgroundImage' style={{width:width,filter:'blur(4px)'}} />,'absolute flexCenter',0)}
	// 		{size_container(<div className='fullHeight fullWidth' style={{backgroundImage:'linear-gradient(to right,rgba(0,0,0,0.8),rgba(0,0,0,0))'}}></div>,'absolute pointerNone',0)}

	// 		{size_container(projectLoop(),'absolute flexCenterx',0)}

	// 		{/*{size_container(<div style={{padding:30}}>
	// 				<div className='bold text_bigger uppercasex whiteColor' style={{textShadow:'0px 0px 20px black',width:height*0.6}}>I love designing and building functional applications for users to interact with.</div>
	// 				<div style={{height:20}}></div>
	// 				<div className='bold text_bigger uppercasex whiteColor' style={{textShadow:'0px 0px 20px black',width:height*0.6}}>I have a passion for creately solving problems using code because its an enviroment that allows for quick and low stake iterations.</div>
	// 			</div>,'absolute flexBottomx',0)}*/}

	// 		{size_container(<ProgramCircleDisplayer width={width} dimensions={{height:40,width:500}} type={{'current':0,}} />,'absolute flexBottom',60)}
	// 	</div>
	// )
}

export default DevelopementAllProjectDisplay