import React from 'react'

import PortfolioData from '../Data/PortfolioData'

const ProjectFullDisplay = ({key_,width}) => {


	function heading(e) {
		return <div className='ggl_text_heading ggl_text_main_color boldx paragraphMarginBottom'>{e}</div>
	}
	function subheading(e) {
		return <div className='ggl_text_main_secondary uppercase ggl_text_main_color paragraphMarginBottom'>{e}</div>
	}
	function body(e) {
		return <div className='ggl_text_smaller ggl_text_secondary_color paragraphMarginBottom'>{e}</div>
	}


	function distributor() {
		const port = PortfolioData[key_]
		let arr = []
		for(let i = 0; i < port.length; i++){
			arr.push(
				<div key={i}>
					{port[i]['type'] === 'heading' ? heading(port[i]['text']) : null}
					{port[i]['type'] === 'subheading' ? subheading(port[i]['text']) : null}
					{port[i]['type'] === 'body' ? body(port[i]['text']) : null}
				</div>
			)
		}
		return arr
	}

	if(PortfolioData[key_] === undefined) return null
	return(
		<div style={{width:width}} className='radialColorBackground'>
			{distributor()}
		</div>
	)
}

export default ProjectFullDisplay