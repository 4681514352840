import React from 'react'

// const wide = 400
const high = 28
const borderRadius = 8

const SliderSelector = ({type,changeType,num_to_key,colors,dimensions}) => {


	let wide = 400
	if(wide > dimensions.width - 20) wide = dimensions.width - 40

	// function textDisplay(num) {
	// 	const rgbs = colors[num]
	// 	let className = 'flexCenter pointer halfOpacity uppercase text_main'
	// 	let color = `rgba(120,120,120,1)`
	// 	if(type['current'] === num && type['count'] > 6){
	// 		className = 'flexCenter pointer uppercase bold whiteTextShadow text_main'
	// 		color = `rgba(${rgbs[0]},${rgbs[1]},${rgbs[2]},1)`
	// 	}
	// 	return <div style={{width:wide/3,height:high,color:color}} className={className} onClick={()=>{changeType(num)}}>{num_to_key[num]}</div>
	// }
	function textDisplay2(num) {
		// const rgbs = colors[num]
		let className = 'flexCenter pointer halfOpacity uppercase ggl_text_smaller'
		let color = `rgba(120,120,120,1)`
		if(type['current'] === num && type['count'] > 6){
			className = 'flexCenter pointer uppercase boldx whiteTextShadowx ggl_text_smaller'
			// color = `rgba(${rgbs[0]},${rgbs[1]},${rgbs[2]},1)`
			color = 'rgb(200,200,200)'
		}
		return <div style={{width:wide/3,height:high,color:color}} className={className} onClick={()=>{changeType(num)}}><div><div style={{height:2}}></div>{num_to_key[num]}</div></div>
	}

	let slide_wide = type['current'] * wide/3
	if(type['count'] < 10){
		const prev_num = type['prev']
		const curr_num = type['current']
		const one_unit = Math.abs(prev_num - curr_num) / 10 * (wide/3)
		if(prev_num < curr_num){
			slide_wide = (prev_num*wide/3) + (one_unit*type['count'])
		} else {
			slide_wide = (prev_num*wide/3) - (one_unit*type['count'])
		}
	}


	return(
		<div style={{width:wide,height:high}} className='fadeInslow'>
			<div style={{width:wide,height:high,backgroundImage:'linear-gradient(rgb(20,20,26),rgb(30,30,36))',border:'1px solid rgb(32,32,40)',boxShadow:'0px 0px 34px rgba(22,22,28,1)',borderRadius:borderRadius}} className='flexCenter absolute pointerNone'></div>
			<div style={{width:wide+2,height:high+2,}} className='flexLeft flexCenter absolute pointerNone'>
				<div style={{width:slide_wide+2}}></div>
				<div style={{width:wide/3-4,height:high-4,borderRadius:borderRadius,border:'1px solid rgb(40,40,44)',boxShadow:'0px 0px 6px rgba(0,0,0,0.5)',backgroundImage:'linear-gradient(rgb(30,30,44),rgb(34,34,40))'}}>
				</div>
			</div>
			<div style={{width:wide+2,height:high+2}} className='flexCenter absolute'>
				{textDisplay2(0)}
				{textDisplay2(1)}
				{textDisplay2(2)}
			</div>
		</div>
	)
	
	// return(
	// 	<div style={{width:wide,height:high}}>
	// 		<div style={{width:wide,height:high,backgroundImage:'linear-gradient(rgb(234,234,234),rgb(245,245,245))',border:'1px solid white',boxShadow:'0px 0px 34px rgba(0,0,0,0.05)',borderRadius:borderRadius}} className='flexCenter absolute pointerNone'></div>
	// 		<div style={{width:wide+2,height:high+2,}} className='flexLeft flexCenter absolute pointerNone'>
	// 			<div style={{width:slide_wide+2}}></div>
	// 			<div style={{width:wide/3-4,height:high-4,borderRadius:borderRadius,border:'1px solid white',boxShadow:'0px 0px 6px rgba(0,0,0,0.2)',backgroundImage:'linear-gradient(rgb(246,246,246),rgb(252,252,252))'}}>
	// 			</div>
	// 		</div>
	// 		<div style={{width:wide+2,height:high+2}} className='flexCenter absolute'>
	// 			{textDisplay(0)}
	// 			{textDisplay(1)}
	// 			{textDisplay(2)}
	// 		</div>
	// 	</div>
	// )
}

export default SliderSelector