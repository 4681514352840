// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	// apiKey: "AIzaSyCoFzrSszDlOXGYAXrK_dLpmanfj9YuWAk",
	// authDomain: "liamfudge-dev.firebaseapp.com",
	// projectId: "liamfudge-dev",
	// storageBucket: "liamfudge-dev.appspot.com",
	// messagingSenderId: "765474399867",
	// appId: "1:765474399867:web:0f1bae13d6c53a3f9864de",
	// measurementId: "G-KNXNTYHLHZ"

	piKey: "AIzaSyAFD9RCbcHZhKHD1zSkDjKtNeD5rsFrtHU",
	authDomain: "resume-liam-fudge.firebaseapp.com",
	projectId: "resume-liam-fudge",
	storageBucket: "resume-liam-fudge.appspot.com",
	messagingSenderId: "687184748314",
	appId: "1:687184748314:web:1ae8de9b32ce18a02596a3",
	measurementId: "G-BQ8D8SBLY7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getFirestore(app);


export { db }