import React, { useState } from 'react'
import { doc, setDoc } from 'firebase/firestore'
import { db } from '../../firebase'

import TextInput from '../MiniComponents/TextInput'

const ContactForm = ({dimensions}) => {
	const [ name, setName ] = useState('')
	const [ email, setEmail ] = useState('')
	const [ subject, setSubject ] = useState('')
	const [ message, setMessage ] = useState('')

	async function sendMessageClicked() {
		const id = String(Math.round(Math.random()*10000000000))
		let object = {
			'id': id,
			'name': name,
			'email': email,
			'subject': subject,
			'message': message,
			'date': String(new Date()),
			'read': false,
		}
		await setDoc(doc(db, 'messages', id), object)
	}



	return(
		<div className='ggl_text_main_secondary ggl_text_secondary_color'>
			<div className='space_height_40'></div>
			<div className='paragraphMarginBottom'>
				<TextInput placeholder='John Doe' value={name} onChange={setName} input_type='text'
						// id={String(Math.round(Math.random()*10000000))}
						label={'Name'}
						component_type='input' enterPressed={()=>{}} width={dimensions.width} height={34} />
			</div>
			<div className='paragraphMarginBottom'>
				<TextInput placeholder='johndoe@email.com' value={email} onChange={setEmail} input_type='email'
						// id={String(Math.round(Math.random()*10000000))}
						label={'Email'}
						component_type='input' enterPressed={()=>{}} width={dimensions.width} height={34} />
			</div>
			<div className='paragraphMarginBottom'>
				<TextInput placeholder='Possible oppotunity...' value={subject} onChange={setSubject} input_type='text'
						// id={String(Math.round(Math.random()*10000000))}
						label={'Subject'}
						component_type='input' enterPressed={()=>{}} width={dimensions.width} height={34} />
			</div>
			<div className='paragraphMarginBottom'>
				<TextInput placeholder='I am writing to enquire about...' value={message} onChange={setMessage} input_type='text'
						// id={String(Math.round(Math.random()*10000000))}
						label={'Message'}
						component_type='textArea' enterPressed={()=>{}} width={dimensions.width} height={204} />
			</div>
			<div className='space_height_20'></div>
			<div className='fadeInslow'>
			{name === '' || email === '' || !email.includes('@') || subject === '' || message === ''
				? <div className='button ggl_text_smaller flexCenter actionColor whiteColor pointerNone halfOpacity'>Send message</div>
				: <div onClick={sendMessageClicked} className='button ggl_text_smaller flexCenter actionColor whiteColor pointer'>Send message</div>
			}
			</div>
		</div>
	)
}

export default ContactForm