import React from 'react'

import ContactForm from '../Contact/ContactForm'

const Contact = ({dimensions}) => {

	let width = 700
	if(dimensions.width - 40 < width) width = dimensions.width - 40
	return(
		<div>
			<div className='flexCenter radialColorBackground homepage_hero_heightx'>
				<div style={{width:dimensions.width-40}}>
					{/*<div style={{height:120}}></div>*/}
					<div className='space_height_60'></div>
					{/*<TextAnimationContainer />*/}
					<div className='ggl_text_hero ggl_text_main_color overflow relative' style={{}}>
						<div className='textAnimateIn absolute textCenter' style={{width:dimensions.width-40}}>Say <b className='textGradient'>hi</b></div>
						<div className='noOpacity'>Say <b className='textGradient'>hi</b></div>
					</div>
					<div className='space_height_40'></div>
					<div className='flexCenter'><div className='ggl_text_main_secondary ggl_text_secondary_color overflow relative' style={{width:dimensions.width-80}}>
						<div className='textAnimateIn2 absolute textCenter' style={{width:dimensions.width-80}}>Feel free to reach out and and ask me whatever</div>
						<div className='noOpacity'>Feel free to reach out and and ask me whatever</div>
					</div></div>

					<div className='space_height_20'></div>

					<div className='flexCenter' style={{}}>
						<ContactForm dimensions={{height:50,width:width}} />
					</div>

				</div>

			</div>
				
		</div>
	)
}

export default Contact