import React, { useState, useEffect, lazy, Suspense } from 'react'
// import React, { useState, useEffect } from 'react'

// import Carosel from '../MiniComponents/Carosel'
// import ChatSquareHero from '../ChatComponent/ChatSquareHero'
// import RenderAllProjectDisplay from '../Rendering/RenderAllProjectDisplay'
import RenderPricing from '../Pricing/RenderPricing'
// import Contact from './Contact'
// import RenderSliderImage from '../Rendering/RenderSliderImage'
import RenderProject from '../Data/RenderProject'
import ContactFormRendering from '../Contact/ContactFormRendering'

import Check from '../../Images/check.svg'
import photorendism_stages from '../../Images/renders/photorendism_stages_1.3.mp4'

const RenderCarouselHomepage = lazy(() => import('../Rendering/RenderCarouselHomepage'))
const HomePageSectionRender2 = lazy(() => import('../Rendering/HomePageSectionRender2'))
const RenderServices = lazy(() => import('../Rendering/RenderServices'))
const Testimonials = lazy(() => import('../MiniComponents/Testimonials'))

// const serviceList = [
// 	{
// 		'name': 'Interior Rendering',
// 		'info': 'Bring your indoor spaces to life with detailed and immersive visuals',
// 		'img': RenderProject['dark_kitchen']['thumb'],
// 		'longest_text': 'Interactive, immersive experiences for presentations with clients',
// 	},
// 	{
// 		'name': 'Exterior Rendering',
// 		'info': 'Showcase how buildings sit in environments',
// 		'img': RenderProject['exterior_render_1']['thumb'],
// 		'longest_text': 'Interactive, immersive experiences for presentations with clients',
// 	},
// 	{
// 		'name': 'Section Renders',
// 		'info': 'Interactive, immersive experiences for presentations with clients',
// 		'img': RenderProject['section_render']['thumb'],
// 		// 'vid': RenderProject['section_render']['video'],
// 		'longest_text': 'Interactive, immersive experiences for presentations with clients',
// 	},
// 	// {
// 	// 	'name': '3D Floor Plans',
// 	// 	'info': 'Help clients visualize layout and spatial relationships',
// 	// 	'img': RenderProject['da_col_mitta']['src'],
// 	// 	'longest_text': 'Interactive, immersive experiences for presentations with clients',
// 	// },
// 	{
// 		'name': 'Animated Renders',
// 		'info': 'Interactive, immersive experiences for presentations with clients',
// 		'img': RenderProject['a_bed2']['thumb'],
// 		'vid': RenderProject['a_bed2']['video'],
// 		'longest_text': 'Interactive, immersive experiences for presentations with clients',
// 	},
// ]

// const carouselData = [RenderProject['exterior_render_1'],RenderProject['dark_kitchen'],RenderProject['sydney_kitchen'],]

const height_multiply = 0.5

const RenderRoute = ({dimensions,route,default_width,navigate}) => {
	const [ loading, setLoading ] = useState(0)
	// const [ slide, setSlide ] = useState(0)

	// const carouselInfinteScroll = () => {
	// 	if(slide === carouselData.length-1) return setSlide(0)
	// 	return setSlide(slide+1)
	// }

	// useEffect(()=>{
	// 	const interval = setInterval(()=>{carouselInfinteScroll()}, 4000)
	// 	return () => clearInterval(interval)
	// })


	useEffect(()=>{
		let count = 0
		let interval = setInterval(()=>{
			if(count > 14){
				clearInterval(interval)
			}
			count+=1
			setLoading(count)
		},200)
		return () => {
			// 
		}
	},[])



	function includedItem(bold,text,width) {
		let left_width = 60
		if(width < 700) left_width = 40
		return(
			<div className='flexHeightCenter fullWidth ggl_text_smaller fadeInslow ggl_text_secondary_color'>
				<div style={{width:left_width}} className='flexCenter'>
					<div className='flexCenter' style={{height:left_width*0.44,width:left_width*0.44,borderRadius:'50%',border:'1px solid white'}}>
						<img src={Check} alt='check' style={{width:left_width*0.32}} />
					</div>
				</div>
				<div style={{width:width-left_width}}><b>{bold}</b>{text}</div>
			</div>
		)
	}
	// function services(width) {
	// 	let arr = []
	// 	let devision = 4
	// 	if(width < 1400) devision = 3
	// 	if(width < 1100) devision = 2
	// 	if(width < 640) devision = 1
	// 	for(let i = 0; i < serviceList.length; i++){
	// 		arr.push(
	// 			<div key={i}>
	// 			<div style={{width:width/devision}} className='flexCenter fadeInslow'>
	// 				<div style={{width:width/devision - 20}} className=' ggl_border borderRadius ggl_text_main_color ggl_text_smaller textCenter overflow'>
	// 					<div style={{width:width/devision - 20,height:(width/devision-20)*0.6}} className='overflow flexCenter'>
	// 						{serviceList[i]['vid'] !== undefined
	// 							? <video loop autoPlay height={(width/devision-20)*0.6}>
	// 								<source src={serviceList[i]['vid']} type="video/mp4" />
	// 							</video>
	// 							: <img src={serviceList[i]['img']} alt='' className='fullHeight' />
	// 						}
	// 					</div>
	// 					{/*<div className='ggl_text_secondary_color' style={{borderTop:'1px solid'}}></div>*/}
	// 					<div style={{padding:20}} className='ggl_box_background'>
	// 						<div>{serviceList[i]['name']}</div>
	// 						<div className='space_height_10'></div>
	// 						<div className='ggl_text_secondary_color overflow relative' style={{width:width/devision - 20 - 40}}>
	// 							<div className='textAnimateIn2 absolute' style={{width:width/devision - 20 - 40}}>{serviceList[i]['info']}</div>
	// 							<div className='noOpacity'>{serviceList[i]['longest_text']}</div>
	// 						</div>
	// 						{/*<div className='ggl_text_secondary_color'>{serviceList[i]['info']}</div>*/}
	// 					</div>
	// 				</div>
	// 			</div>
	// 			<div className='space_height_20'></div>
	// 			</div>
	// 		)
	// 	}
	// 	return <div className='flexCenter textAnimateInBig' style={{flexWrap:'wrap'}}>{arr}</div>
	// }

	let height = default_width*height_multiply
	if(default_width < 850) height = default_width*0.6
	if(default_width < 600) height = default_width*0.7
	if(default_width < 450) height = default_width*0.8
	// let padding = 40
	// if(default_width < 1000) padding = 20
	let contact_width = 700
	if(dimensions.width - 40 < contact_width) contact_width = dimensions.width - 40
	let process_multiplier = 0.5
	if(default_width < 1100) process_multiplier = 0.6
	if(default_width < 1000) process_multiplier = 0.7

	// if(){
	// 	return(
	// 		<div></div>
	// 	)
	// }

	// if(dimensions.width < 500000){
	// 	return(
	// 		<div>
	// 			<img src={'https://firebasestorage.googleapis.com/v0/b/resume-liam-fudge.appspot.com/o/exterior_render_1.png?alt=media&token=a1693d95-3cd7-49a8-add5-2c57927c85f3'} alt='online_storage' />
	// 		</div>
	// 	)
	// }
	return(
		<div className='fadeIn'>

			<div style={{width:dimensions.width,height:dimensions.width*0.64}} className='flexCenterx overflow absolute fadeInslow'>
				<div className='absolute flexCenter' style={{width:dimensions.width,height:dimensions.width*0.64}}>
					<img src={RenderProject['exterior_render_1']['thumb']} alt='render_image_thumbnail' className='fullHeight' />
				</div>
				{loading > 1
					? <Suspense fallback={<div></div>}>
						<RenderCarouselHomepage dimensions={dimensions} />
					</Suspense>
					: null
				}
				{/*<div className='carousel-container'>
					{carouselData.map((item,index) => {
						return <div className='carousel-item' style={{transform:`translate(-${slide * 100}%)`}} key={index}>
							<div style={{width:dimensions.width,height:dimensions.width*0.64}} className='flexCenter overflow'>
								{dimensions.width < 5700
									? <img src={item['thumb']} alt='hero_image' className='fullHeight' />
									: <img src={item['src']} alt='hero_image' className='fullHeight' />
								}
							</div>
						</div>
					})}
				</div>*/}
			</div>
			<div className='relative flexCenter' style={{width:dimensions.width,height:dimensions.width*0.64,backgroundImage:'radial-gradient(rgba(0,0,0,0.37),rgba(0,0,0,0))'}}>
				<div>
					<div className='ggl_text_hero ggl_text_main_color overflow relative' style={{width:default_width}}>
						<div className='textAnimateIn absolute textCenter flexCenter' style={{width:default_width}}><div style={{fontStyle:'italic'}} className='ggl_textShadow'>Photo</div><b className='textGradientArch'>Rendism</b></div>
						<div className='noOpacity flexCenter'><div style={{fontStyle:'italic'}}>Photo</div><b className='textGradientArch'>Rendism</b></div>
					</div>
					{/*<div className='ggl_text_hero ggl_text_main_color textCenter flexCenter' style={{width:dimensions.width*0.7}}><div style={{fontStyle:'italic'}}>Photo</div><b className='textGradientArch'>Rendism</b></div>*/}
					<div className='ggl_text_main_secondary ggl_text_main_color overflow relative ggl_textShadow' style={{width:default_width}}>
						<div className='textAnimateIn2 absolute textCenter' style={{width:default_width}}>Bringing Your Architectural Visions to Life</div>
						<div className='noOpacity'>Bringing Your Architectural Visions to Life</div>
					</div>
					{/*<div className='textCenter ggl_text_main_secondary ggl_text_main_color'>Bringing Your Architectural Visions to Life</div>*/}
					<div className='space_height_20'></div>
					<div className='overflow relative'>
						<div className='flexCenter textAnimateInBig absolute' style={{width:default_width}}>
							<div 
									// style={{backgroundImage:'linear-gradient(40deg,rgb(40,180,230),rgb(40,220,180))',height:40,borderRadius:20,width:200}}
									className='flexCenter pointer ggl_text_main_colorx boldx ggl_text_smaller cta_render_button'>Start your project</div>
						</div>
						<div className='flexCenter noOpacity'>
							<div 
									// style={{backgroundImage:'linear-gradient(40deg,rgb(40,180,230),rgb(40,220,180))',height:40,borderRadius:20,width:200}}
									className='flexCenter pointer ggl_text_main_colorx boldx ggl_text_smaller cta_render_button'>Start your project</div>
						</div>
					</div>
				</div>
			</div>

			<div className='radialColorBackground'>
				<div className='space_height_60'></div>

				<div className='flexCenter ggl_text_main_color'>
					<div style={{width:default_width}}>

						<div className='ggl_text_heading ggl_text_main_color overflow relative' style={{width:default_width}}>
							<div className='textAnimateIn absolute textCenter' style={{width:default_width}}>About Us</div>
							<div className='noOpacity'>About Us</div>
						</div>
						<div className='space_height_40'></div>

						<div className='ggl_text_main_secondary ggl_text_main_color overflow relative' style={{width:default_width}}>
							<div className='textAnimateIn2 absolute textCenterx bold' style={{width:default_width}}>Turning Concepts into Realities</div>
							<div className='noOpacity bold'>Turning Concepts into Realities</div>
						</div>

						{/*<div className='ggl_text_main_secondary bold'>Turning Concepts into Realities</div>*/}
						<div className='space_height_20'></div>
						<div className='ggl_text_smaller ggl_text_secondary_color fadeInslow'>At PhotoRendism, we specialize in creating photorealistic architectural renderings that bring your designs to life. Our goal is simple: to provide architects, designers, and developers with the visual tools they need to make informed decisions, impress clients, and effectively communicate their vision.</div>
						<div className='space_height_10'></div>
						<div className='ggl_text_smaller ggl_text_secondary_color fadeInslow'>With years of experience and a passion for precision, we use cutting-edge technology and advanced 3D modeling techniques to deliver high-quality, lifelike renderings that capture every detail. Whether you're looking to showcase a building's exterior, visualize an interior space, or create an interactive virtual tour, we tailor each project to meet your unique needs.</div>
						<div className='space_height_10'></div>
						<div className='ggl_text_smaller ggl_text_secondary_color fadeInslow'>Our team is dedicated to delivering exceptional results with fast turnaround times and a commitment to excellence. We work closely with you throughout the entire process, ensuring that your vision is realized with the utmost clarity and accuracy.</div>
						<div className='space_height_10'></div>
						{/*<div className='ggl_text_smaller'></div>*/}

						<div className='space_height_60'></div>

						<div className='ggl_text_main_secondary ggl_text_main_color overflow relative' style={{width:default_width}}>
							<div className='textAnimateIn2 absolute textCenterx bold' style={{width:default_width}}>Why choose us?</div>
							<div className='noOpacity bold'>Why choose us?</div>
						</div>
						{/*<div className='ggl_text_main_secondary bold'>Why choose us?</div>*/}
						<div className='space_height_20'></div>

						{includedItem('Realistic, High-Detail Renders:',' We focus on creating visually stunning, true-to-life images that help your projects stand out.',default_width)}
						<div className='space_height_20'></div>
						{includedItem('Client-Centered Approach:',' We listen to your goals and collaborate to ensure your vision is fully realized.',default_width)}
						<div className='space_height_20'></div>
						{includedItem('Fast, Reliable Delivery:',' We understand deadlines, so we provide quick turnaround times without compromising quality.',default_width)}
						<div className='space_height_20'></div>
						{includedItem('Innovative Techniques:',' Using the latest software and 3D rendering technology, we push the boundaries of design visualization.',default_width)}
						<div className='space_height_20'></div>


						<div className='space_height_60'></div>
						<div className='space_height_60'></div>

						{loading > 1
							? <div>
								<div className='ggl_text_heading ggl_text_main_color overflow relative' style={{width:default_width}}>
									<div className='textAnimateIn absolute textCenter' style={{width:default_width}}>Our Services</div>
									<div className='noOpacity'>Our Services</div>
								</div>
								<div className='space_height_40'></div>


								<Suspense fallback={<div></div>}>
									<RenderServices width={default_width} />
								</Suspense>
							</div>
							: <div style={{height:600}}></div>
						}


					</div>
				</div>
			</div>



			{loading > 1
				? <div>
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>

					<div className='ggl_text_heading ggl_text_main_color overflow relative' style={{width:dimensions.width}}>
						<div className='textAnimateIn absolute textCenter' style={{width:dimensions.width}}>Our Work</div>
						<div className='noOpacity'>Our Work</div>
					</div>
					<div className='space_height_40'></div>


					<Suspense fallback={<div></div>}>
						<div className='flexCenter'><div style={{width:default_width}}><HomePageSectionRender2 width={default_width} fullDisplay={false} text_display={false} num_of_rows={6} /></div></div>
					</Suspense>
				</div>
				: null
			}


			{loading > 3
				? <div>
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>

					<div className='radialColorBackground'>
						{/*<div className='space_height_60'></div>*/}

						<div className='flexCenter ggl_text_main_color'>
							<div style={{width:default_width}}>

								<div className='ggl_text_heading ggl_text_main_color overflow relative' style={{width:default_width}}>
									<div className='textAnimateIn absolute textCenter' style={{width:default_width}}>How We Work</div>
									<div className='noOpacity'>How We Work</div>
								</div>
								<div className='space_height_20'></div>
								<div className='ggl_text_main_secondary ggl_text_main_color overflow relative' style={{width:default_width}}>
									<div className='textAnimateIn2 absolute textCenter bold' style={{width:default_width}}>From Concept to Reality</div>
									<div className='noOpacity bold'>From Concept to Reality</div>
								</div>

								<div className='space_height_40'></div>


								{default_width < 700
									? <div>
										<div style={{width:default_width,height:height}} className='flexCenter overflow borderRadius'>
											<video loop autoPlay height={height*1.2}>
												<source src={photorendism_stages} type="video/mp4" />
											</video>
										</div>
										<div className='space_height_40'></div>
										{includedItem('Consultation:',' We discuss your project goals and requirements.',default_width)}
										<div className='space_height_20'></div>
										{includedItem('Concept Development:',' We craft initial designs and start the rendering process.',default_width)}
										<div className='space_height_20'></div>
										{includedItem('3D Modeling & Texturing:',' We build accurate 3D models with realistic textures and lighting.',default_width)}
										<div className='space_height_20'></div>
										{includedItem('Final Render:',' We deliver photorealistic renders ready for your presentation or marketing.',default_width)}
										<div className='space_height_20'></div>
										{includedItem('Revisions & Delivery:',' We offer revisions based on your feedback, ensuring your satisfaction.',default_width)}
										<div className='space_height_20'></div>
									</div>
									: <div className='flexLeft'>
										<div style={{width:default_width*0.4,height:height*process_multiplier}} className='flexCenter overflow borderRadius'>
											<video loop autoPlay height={height*1.2*process_multiplier}>
												<source src={photorendism_stages} type="video/mp4" />
											</video>
										</div>
										<div style={{width:default_width*0.02}}></div>
										<div>
											<div style={{height:height*process_multiplier/5}} className='flexHeightCenter'>
												{includedItem('Consultation:',' We discuss your project goals and requirements.',default_width*0.57)}
											</div>
											<div style={{height:height*process_multiplier/5}} className='flexHeightCenter'>
												{includedItem('Concept Development:',' We craft initial designs and start the rendering process.',default_width*0.57)}
											</div>
											<div style={{height:height*process_multiplier/5}} className='flexHeightCenter'>
												{includedItem('3D Modeling & Texturing:',' We build accurate 3D models with realistic textures and lighting.',default_width*0.57)}
											</div>
											<div style={{height:height*process_multiplier/5}} className='flexHeightCenter'>
												{includedItem('Final Render:',' We deliver photorealistic renders ready for your presentation or marketing.',default_width*0.57)}
											</div>
											<div style={{height:height*process_multiplier/5}} className='flexHeightCenter'>
												{includedItem('Revisions & Delivery:',' We offer revisions based on your feedback, ensuring your satisfaction.',default_width*0.57)}
											</div>
										</div>
									</div>
								}

									

								<div className='space_height_60'></div>
								<div className='space_height_60'></div>
								<div className='space_height_60'></div>

								<div className='ggl_text_heading ggl_text_main_color overflow relative' style={{width:default_width}}>
									<div className='textAnimateIn absolute textCenter' style={{width:default_width}}>What Our Clients Say</div>
									<div className='noOpacity'>What Our Clients Say</div>
								</div>
								<div className='space_height_40'></div>
							</div>
						</div>
					</div>

					<Suspense fallback={<div></div>}>
						<Testimonials width={default_width} dimensions={dimensions} />
					</Suspense>
				</div>
				: null
			}


			{loading > 4
				? <div>
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>


					<div className='radialColorBackground'>
						<div className='flexCenter'>
							<div className='ggl_text_heading ggl_text_main_color overflow relative' style={{width:default_width}}>
								<div className='textAnimateIn absolute textCenter' style={{width:default_width-40}}>Prices</div>
								<div className='noOpacity'><b className='textGradientx'>Prices</b> </div>
							</div>
						</div>
						<div className='space_height_20'></div>
						<div className='flexCenter'>
							<RenderPricing width={default_width} />
						</div>
					</div>


					<div className='radialColorBackground'>
						<div className='space_height_60'></div>
						<div className='space_height_60'></div>

						<div className='flexCenter ggl_text_main_color'>
							<div style={{width:contact_width}}>

								<div className='ggl_text_heading ggl_text_main_color overflow relative' style={{width:contact_width}}>
									<div className='textAnimateIn absolute textCenter' style={{width:contact_width}}>Start Your Project</div>
									<div className='noOpacity'>Start Your Project</div>
								</div>
								<div className='space_height_40'></div>
								<div className='ggl_text_main_secondary ggl_text_secondary_color overflow relative' style={{width:contact_width}}>
									<div className='textAnimateIn2 absolute textCenter' style={{width:contact_width}}>If you're ready to start your project, fill out the form and we'll get in touch</div>
									<div className='noOpacity'>If you're ready to start your project, fill out the form and we'll get in touch</div>
								</div>
								<div className='space_height_40'></div>
							</div>
						</div>

						<div className='flexCenter' style={{}}>
							<ContactFormRendering dimensions={{height:50,width:contact_width}} />
						</div>
					</div>
				</div>
				: null
			}


			<div className='space_height_60'></div>
			<div className='space_height_60'></div>

		</div>
	)
}

export default RenderRoute