import React from 'react'

import Check from '../../Images/check.svg'

const priceOptions = [
	{
		flexClass: 'flexLeft',
		name: 'First',
		subheading: 'There is no pressure on your first render, you get to know my style and have no risk envolved',
		price: 0,
		includes: [
			'High quality architectural rendering',
		],
	},
	{
		flexClass: 'flexCenter',
		name: 'Interior',
		subheading: 'Sell a client on an interior render with a fast turn-around and high quality',
		price: 350,
	},
	{
		flexClass: 'flexRight',
		name: 'Exterior',
		subheading: 'Sell a client on an interior render with a fast turn-around and high quality',
		price: 450,
	},
]

const RenderPricing = ({width}) => {

	function includedItem(text) {
		return(
			<div className='flexHeightCenter fullWidth ggl_text_smaller'>
				<div style={{width:'20%'}} className='flexCenter'>
					<div className='flexCenter' style={{height:24,width:24,borderRadius:'50%',border:'1px solid white'}}>
						<img src={Check} alt='check' style={{width:18}} />
					</div>
				</div>
				<div style={{width:'80%'}}>{text}</div>
			</div>
		)
	}

	function priceDisplayer(width,data) {
		return(
			<div style={{width:width}} className='ggl_box_background ggl_border borderRadius ggl_text_main_color ggl_text_main_secondary'>
				<div style={{padding:30}}>
					<div className='bold'><b className='textGradientLighter'>{data['name']}</b> render</div>
					<div className='space_height_20'></div>
					<div className='ggl_text_smaller'>{data['subheading']}</div>
					<div className='space_height_20'></div>
					<div style={{borderTop:'1px solid'}} className='fullWidth ggl_text_secondary_color'></div>
					<div className='space_height_20'></div>
					<div className='flexHeightCenter'>
						<div className='' style={{marginBottom:'13%'}}>$</div>
						<div className='ggl_text_hero'>{data['price']}</div>
						<div className='' style={{marginTop:'11%'}}>AUD/render</div>
					</div>
					<div className='space_height_40'></div>

					{includedItem('Access to our 1.5 Flash model')}
					<div className='space_height_20'></div>
					{includedItem('32K token context window for long back-and-forth conversations')}
					<div className='space_height_20'></div>
					{includedItem('Help with writing, planning, learning and more')}
					<div className='space_height_20'></div>
					{includedItem('Access information and get things done across multiple Google apps at once')}
					<div className='space_height_20'></div>
					{/*{includedItem('')}*/}
				</div>
			</div>
		)
	}
	function priceLoop() {
		let arr = []
		let devisions = 3
		if(width < 1100) devisions = 2
		if(width < 700) devisions = 1
		let boxWidth = width/devisions-20
		if(boxWidth > 440) boxWidth = 440
		for(let i = 0; i < priceOptions.length; i++){
			arr.push(
				<div key={i} style={{width:width/devisions}} className='flexCenter'>
					<div>
						{priceDisplayer(boxWidth,priceOptions[i])}
						<div className='space_height_40'></div>
					</div>
				</div>
			)
		}
		return <div className='flexLeft' style={{flexWrap:'wrap'}}>{arr}</div>
	}
	return(
		<div style={{width:width}}>
			{priceLoop()}
		</div>
	)
}

export default RenderPricing