import React from 'react'

import ChatInput from './ChatInput'

const ChatSquareHero = ({width,height,padding,navigate}) => {

	function size_container(component,position,minus) {
		const className = 'overflow ' + position
		return <div style={{height:height-minus,width:width-minus,marginLeft:(minus/2),marginTop:(minus/2)}} className={className}>{component}</div>
	}

	let text_input_width = 600
	if(width < text_input_width) text_input_width = width
	return(
		<div style={{width:width,height:height}} className='overflow relative ggl_border borderRadius'>
			{size_container(<div className='fullHeight fullWidth ggl_box_background'></div>,'absolute pointerNone',0)}

			{size_container(
				<div className='' style={{width:text_input_width-padding}}>
					<div className='ggl_text_heading ggl_text_main_color overflow relative'>
						<div className='textAnimateIn absolute textCenter' style={{width:text_input_width-padding}}>Chat with l<b className='textGradientLighter noBold'>a.i.</b>m</div>
						<div className='noOpacity'>Chat with l<b className='textGradientLighter noBold'>a.i.</b>m</div>
					</div>

					<div className='space_height_10'></div>

					<div className='ggl_text_secondary_color overflow relative' style={{}}>
						<div className='textAnimateIn2 absolute textCenter' style={{width:text_input_width-padding}}>This is the Llama LLM fined tuned on all of my information</div>
						<div className='noOpacity'>This is the Llama LLM fined tuned on all of my information</div>
					</div>

					<div className='space_height_40'></div>

					<ChatInput width={text_input_width-padding} />

					<div className='space_height_40'></div>

					<div className='overflow' style={{height:30}}>
						<div className='flexCenter textAnimateIn2 absolute' style={{width:text_input_width-padding}}>
							<div className='actionColor ggl_text_main_color ggl_text_smaller flexCenter pointer' style={{borderRadius:6,height:30,width:140}} onClick={()=>{navigate('projects')}}>Learn more</div>
						</div>
					</div>
				</div>,'absolute flexCenter',0)}
		</div>
	)
}

export default ChatSquareHero