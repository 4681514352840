import React from 'react'

import arrow_right from '../../Images/arrow-right.svg'

const ChatInput = ({width}) => {

	// const width = 600
	const height = 40
	return(
		<div style={{width:width,height:height,backgroundImage:'linear-gradient(45deg,rgb(50,70,90),rgb(40,100,120))',borderRadius:6}} className='flexCenter fadeInslow'>
			<div style={{width:width-2,height:height-2,borderRadius:6}} className='blackBackground ggl_text_secondary_color flexHeightCenter ggl_text_smaller overflow'>
				<div style={{paddingLeft:12,width:width-4-height-7}} className='textAnimateIn2'>Ask me anything...</div>
				<div style={{height:height-4,width:height-4,borderTopRightRadius:6,borderBottomRightRadius:6}} className='flexCenter pointer textAnimateIn'>
					<img src={arrow_right} alt='arrow_right' style={{height:20}} />
				</div>
			</div>
		</div>
	)
}

export default ChatInput