import React from 'react'
import { connect } from 'react-redux'

import WaveVisualizerLight from './WaveVisualizerLight'

const BottomPlaybackBar = ({selected_track,play,dimensions,audio_time,playClicked,pauseClicked,seekAudioTime}) => {

	function playButtonClicked() {
		if(play === true){
			pauseClicked()
		} else {
			playClicked()
		}
	}
	function setAudioPlaybackPoint(e) {
		seekAudioTime(e)
	}
	
	if(selected_track['audio_path'] === undefined) return null
	return(
		<div className='fixed flexCenter' style={{bottom:0,left:0,right:0,height:80,background:'black',color:'white'}}>
			<div onClick={playButtonClicked}>{play === false ? 'PLAY' : 'PAUSE'}</div>
			<div>{selected_track['name']}</div>

			<WaveVisualizerLight width={600} height={80} selected={selected_track} time_keeper={audio_time} setAudioPlaybackPoint={setAudioPlaybackPoint} />
		</div>
	)
}

// export default BottomPlaybackBar

const mapStateToProps = (state) => ({
	dimensions: state.dimensions.dimensions,
	selected_track: state.handlePlayback.selected_track,
	play: state.handlePlayback.play,
	audio_time: state.handlePlayback.audio_time,
})
const mapDispatchToProps = (dispatch) => ({
	// loadAudioFile: (e) => dispatch({type: 'LOAD_AUDIO_FILE_INITIATE',payload: e}),
	playClicked: () => dispatch({type: 'PLAY_CLICKED'}),
	pauseClicked: () => dispatch({type: 'PAUSE_CLICKED'}),
	seekAudioTime: (e) => dispatch({type: 'SEEK_AUDIO_TIME',payload: e}),
})

export default connect(mapStateToProps, mapDispatchToProps)(BottomPlaybackBar)