import React from 'react'
import { connect } from 'react-redux'

const TextInput = ({mobile,placeholder,value,onChange,input_type,id,label,component_type,enterPressed,width,height}) => {

	function output(e) {
		onChange(e.target.value)
	}
	function keyPressed(e) {
		if(e.key === 'Enter'){
			enterPressed()
		}
	}


	function inputComponent(width,height,component_type) {
		return(
			<div style={{width:width,height:height,backgroundImage:'linear-gradient(45deg,rgb(50,70,90),rgb(40,100,120))',borderRadius:6}} className='flexCenter fadeInslow'>
				<div style={{width:width-2,height:height-2,borderRadius:6}} className='blackBackground ggl_text_secondary_color flexHeightCenter ggl_text_smaller overflow'>
					{/*<div style={{paddingLeft:12,width:width-4-height-7}} className='textAnimateIn2'>Ask me anything...</div>*/}
					{component_type === 'input'
						? <input className='textInput' placeholder={placeholder} value={value} type={input_type}
								onChange={output} onKeyDown={keyPressed}
								// style={{height:height,width:width-12,paddingLeft:12,paddingRight:0,paddingTop:0,paddingBottom:0,fontSize:16}}
								id={id} />
						: <textarea className='textInput textArea' placeholder={placeholder} value={value} type={input_type}
								onChange={output} onKeyDown={keyPressed}
								// style={{height:height,width:width-12,paddingLeft:12,paddingRight:0,paddingTop:0,paddingBottom:0,fontSize:16}}
								id={id} />
					}
						
					{/*<div style={{height:height-4,width:height-4,borderTopRightRadius:6,borderBottomRightRadius:6}} className='flexCenter pointer textAnimateIn'>
						<img src={arrow_right} alt='arrow_right' style={{height:20}} />
					</div>*/}
				</div>
			</div>
		)
	}


	return(
		<div>
			{label === null | label === '' || label === undefined
				? null
				: <div className='labelMarginBottom ggl_text_smaller overflow relative'>
					<div className='textAnimateIn absolute' style={{width:width}}>{label}</div>
					<div className='noOpacity'>{label}</div>
				</div>
			}
				
			{inputComponent(width,height+6,component_type)}
			{/*{component_type === 'input'
				? mobile === true
					? <input className='' placeholder={placeholder} value={value} type={input_type}
							onChange={output} onKeyDown={keyPressed}
							style={{height:height,width:width-12,paddingLeft:12,paddingRight:0,paddingTop:0,paddingBottom:0,fontSize:16}} id={id} />
					: <input className='' placeholder={placeholder} value={value} type={input_type}
							onChange={output} onKeyDown={keyPressed}
							style={{height:height,width:width-12,paddingLeft:12,paddingRight:0,paddingTop:0,paddingBottom:0,}} id={id} />
				: mobile === true
					? <textarea className='' placeholder={placeholder} value={value} type={input_type}
							onChange={output} onKeyDown={keyPressed}
							style={{height:height,width:width-12,paddingLeft:12,paddingRight:0,paddingTop:0,paddingBottom:0,fontSize:16}} id={id} />
					: <textarea className='' placeholder={placeholder} value={value} type={input_type}
							onChange={output} onKeyDown={keyPressed}
							style={{height:height,width:width-12,paddingLeft:12,paddingRight:0,paddingTop:0,paddingBottom:0,}} id={id} />
			}*/}
		</div>
	)
}

// export default TextInput

const mapStateToProps = (state) => ({
	mobile: state.mobile.mobile
})
const mapDispatchToProps = (dispatch) => ({
	// increment: () => dispatch({type: 'INCREMENT' }),
	// decrement: () => dispatch({type: 'DECREMENT' })
})

export default connect(mapStateToProps, mapDispatchToProps)(TextInput)