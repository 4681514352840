import React from 'react'
import { connect } from 'react-redux'

import RenderProject from '../Data/RenderProject'

const HomePageSectionRender = ({width,fullDisplay,modelClicked,navigate,text_display}) => {


	function imageDisplay(im,w,h) {
		return <div style={{width:w-4,height:h-4,borderRadius:4}} className='overflow flexCenter pointer blackBackground fadeIn'
				onClick={()=>{modelClicked({type:'render',data:im})}}
				>
			{im['type'] === 'wide'
				? <img src={im['src']} alt='render' className='fullWidth imageHoverSizeUp' />
				: <img src={im['src']} alt='render' className='fullHeight imageHoverSizeUp' />
			}
		</div>
	}

	function boxdisplay() {
		let devider = 3
		if(width > 600) devider = 4
		if(width > 700) devider = 5
		if(width > 1000) devider = 6
		if(width > 1200) devider = 7
		if(width > 1400) devider = 8
		// if(width > 1600) devider = 9
		// if(width > 1800) devider = 10
		// if(width > 2000) devider = 11
		let rows = 3
		let arr = []
		let count = 0
		let used = []
		let largest_row = 0
		for(const [key, val] of Object.entries(RenderProject)){

			let wide = width / devider
			let high = wide

			let tempCount = count
			if(used.includes(tempCount)){
				let found = false
				while(found === false){
					tempCount+=1
					if(!used.includes(tempCount)){
						if(val['type'] === 'wide'){
							if(tempCount%devider !== devider-1){
								if(!used.includes(tempCount+1)){
									found = true
								}
							}
						} else {
							found = true
						}
					}
				}
			}
			used.push(tempCount)
			let x = tempCount%devider * wide
			let y = Math.floor(tempCount / devider) * wide
			if(val['type'] === 'high'){
				high = high * 2
				used.push(tempCount+devider)
			} else if(val['type'] === 'wide'){
				wide = wide * 2
				used.push(tempCount+1)
			}
			arr.push(
				<div key={key}>
					<div style={{width:wide,height:high,marginTop:y,marginLeft:x}} className='flexCenter absolute'>
						{imageDisplay(val,wide,high)}
						{/*<div style={{color:'green'}}>{count}</div>*/}
					</div>
				</div>
			)

			count+=1
			largest_row = Math.floor(tempCount / devider) + 1
			if(val['type'] === 'high') largest_row+=1
		}
		if(fullDisplay === true){
			return <div style={{height:(largest_row+1)*(width/devider)}}>{arr}</div>
		}
		return(
			<div className='fadeInslow' style={{width:width}}>
				{text_display === false
					? null
					: <div style={{height:rows*(width/devider),width:width,backgroundImage:'radial-gradient(rgba(0,0,0,0.9),rgba(0,0,0,0))',zIndex:2}} className='overflow absolute flexCenter'>
						<div>
							<div className='ggl_text_heading ggl_text_main_color textCenter'><b className='ggl_textShadow noBold'>Architectural</b> <b className='textGradientLighter noBold'>visualizations</b></div>
							<div className='space_height_10'></div>
							<div className='ggl_text_main_colorx ggl_text_secondary_color textCenter ggl_textShadow'>I have worked with architects and landscaper designers to bring ideas to life</div>
							<div className='space_height_40'></div>
							<div className='flexCenter flexRightx'>
								<div className='actionColor ggl_text_main_color ggl_text_smaller flexCenter pointer' style={{borderRadius:6,height:30,width:140}} onClick={()=>{navigate('rendering')}}>Learn more</div>
							</div>
						</div>
					</div>
				}
				<div style={{height:rows*(width/devider)}} className='overflow relative'>{arr}</div>
			</div>
		)
	}

	return boxdisplay()
}

// export default HomePageSectionRender

const mapStateToProps = (state) => ({
	modal: state.modal.modal
})
const mapDispatchToProps = (dispatch) => ({
	modelClicked: (e) => dispatch({type: 'MODAL_CLICKED', payload: e }),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePageSectionRender)