import React, { useState } from 'react'

import SkillGraph from './SkillGraph'
import SliderSelector from './SliderSelector'

// const container_height = 400
// const container_width = 600
const num_to_key = ['skill','passion','experience']
// const colors = [[20,60,180],[10,130,40],[90,20,130]]
const colors = [[120,20,160],[210,60,100],[60,90,200]]
// [[170,80,120],[170,10,80]],

// const topic_descriptions = {
// 	'react': {
// 		'desc': 'I’ve become quite proficient in React through a mix of self-study and hands-on projects, thanks to numerous Udemy courses and Youtube. One of my standout projects is a music generation site that lets users edit every note and sound, all built from scratch using React. I take pride in ensuring everything is original and legal, as the project doesn’t rely on A.I. or any copyrighted material.',
// 	},
// 	'react native': {
// 		'desc': 'One of my first major projects was creating "the Uber for chefs," a platform that connects customers with chefs who can come to their homes and prepare delicious meals. I developed three frontends—one for customers, one for chefs, and another for restaurants to manage their chefs. Initially, I built it using React, but I later expanded the project by creating three mobile apps with React Native to enhance the user experience. Additionally, I designed a Halloween "trick or treating" app that allowed users to virtually trick-or-treat with friends via video chat due to the Covid lockdown restrictions. These projects not only honed my skills in React and React Native but also fueled my passion for creating innovative solutions that bring people together!',
// 	},
// 	'node': {
// 		'desc': 'For my music generation platform, I initially built everything on the frontend using plain vanilla JavaScript. However, I decided to transition to a Node.js backend to better protect my intellectual property. I take pride in demonstrating that you can create a robust music generation system using just music theory and straightforward coding techniques, without relying on A.I. or infringing on artists’ copyrights. This approach not only showcases my technical abilities but also reflects my commitment to originality and ethical practices in music creation!',
// 	},
// 	'python': {
// 		'desc': 'I developed a high-quality audio generation system using Python that’s allowed me to push the boundaries of music creation. One of my proudest achievements was having several songs generated by this system accepted by Hans Zimmer’s film and TV music company. Building the entire system from the ground up, I focused on producing rich, high-quality sound that meets industry standards. This experience has not only deepened my technical skills in audio production but also reinforced my passion for exploring innovative ways to create music!',
// 	},
// 	'ai': {
// 		'desc': 'I’ve dived into the world of artificial intelligence through various Udemy courses, focusing on deep learning and machine learning. I’ve explored cool architectures like Transformers, CNNs, and VAEs. I think it is really important to understand the math behind them, not just know how to use a few libraries like Tensorflow and Pytorch. I practice by building a simple neural network from scratch using the MNIST dataset of handwritten digits, just using Python and Numpy.',
// 	},
// 	'music': {
// 		'desc': 'I’ve been passionate about music from a young age, and I had the incredible opportunity to get signed as a producer to a label in L.A. This experience allowed me to collaborate with some big-name artists, and I’m proud to say that my work has garnered over 100 million streams. Creating and producing music has not only shaped my artistic voice but also taught me the ins and outs of the industry. I love blending my musical background with technology, which drives my projects and creative endeavors today!',
// 	},
// 	'blender': {
// 		'desc': 'I taught myself how to use Blender and quickly found my niche in architectural rendering. My skills led me to a brief but rewarding opportunity working for my uncle, a prominent garden designer in Australia. I created 3D renders of his garden designs, which he used as a compelling sales tool to present to clients. This experience not only sharpened my 3D rendering skills but also taught me how to visually communicate design concepts effectively. I love bringing ideas to life and enjoy the creative challenges that come with 3D design!',
// 	},
// }

const Skills = ({width,dimensions}) => {
	const [ type, setType ] = useState({prev:0,current:0,count:11})  // skill passion experience
	const [ topic, setTopic ] = useState('react')

	function changeType(e) {
		if(e !== type['current']){
			let count = 1
			setType({prev:type['current'],current:e,count:count})
			let interval = setInterval(()=>{
				count+=1
				setType({prev:type['current'],current:e,count:count})
				if(count > 10) clearInterval(interval)
			},20)
		}
	}


	// const container_height = width/2 * .6
	// const container_width = width/2

	let full_width = width
	if(width > 800) full_width = 800

	// if(width < 700){
		return(
			<div className='flexCenter ggl_text_main_color' style={{width:dimensions.width}}>
				<div style={{width:full_width}}>
					<div className='flexCenterx'>
						<div style={{}}>
							<SkillGraph type={type} rgb_color_array={colors[type['current']]} height={full_width*0.6} width={full_width-20} num_to_key={num_to_key} setTopic={setTopic} topic={topic} />
						</div>
						<div className='space_height_60'></div>
						<div className='flexCenter'><SliderSelector type={type} changeType={changeType} num_to_key={num_to_key} colors={colors} dimensions={dimensions} /></div>

						{/*<div className='flexCenter textCenterx' style={{width:width}}>
							<div style={{padding:20}}>
								<div className='uppercase boldx text_subheadingx paragraphMarginBottom ggl_text_main_secondary flexCenter'>{topic}</div>
								<div className='ggl_text_main_secondaryx ggl_text_smaller ggl_text_secondary_color'>{topic_descriptions[topic]['desc']}</div>
							</div>
						</div>*/}

					</div>
				</div>
			</div>
		)
	// }
	// return(
	// 	<div className='flexCenter ggl_text_main_color' style={{width:dimensions.width}}>
	// 		<div style={{width:width}}>
				
	// 			<div className='flexCenterx flexLeft' style={{}}>
	// 				<div className='flexCenterx textCenterx' style={{width:container_width-40}}>
	// 					<div style={{paddingLeft:30,paddingRight:40}}>
	// 						<div className='uppercase ggl_text_main_secondary ggl_text_main_color overflow relative' style={{}}>
	// 							<div className='textAnimateIn absolute textCenterx' style={{width:container_width-40-30}}>{topic}</div>
	// 							<div className='space_height_20'></div>
	// 							<div className='noOpacity'>{topic}</div>
	// 						</div>
	// 						{/*<div className='uppercase boldx text_subheadingx paragraphMarginBottom ggl_text_main_secondary ggl_text_headingx'>{topic}</div>*/}
	// 						<div className='ggl_text_main_secondaryx ggl_text_smaller ggl_text_secondary_color fadeInslow'>{topic_descriptions[topic]['desc']}</div>
	// 					</div>
	// 				</div>

	// 				<div style={{width:container_width}}>
	// 					<SkillGraph type={type} rgb_color_array={colors[type['current']]} height={container_height} width={container_width} num_to_key={num_to_key} setTopic={setTopic} topic={topic} />
	// 					<div className='space_height_20'></div>
	// 					<div className='flexCenter'><SliderSelector type={type} changeType={changeType} num_to_key={num_to_key} colors={colors} dimensions={dimensions} /></div>
	// 				</div>

	// 			</div>
	// 		</div>
	// 	</div>
	// )
}

export default Skills