import React from 'react'

import check from '../../Images/check.svg'

const CheckBox = ({state,condition,text,dimensions}) => {

	let check_wide = 16
	// if(dimensions.width < )
	return(
		<div className='flexHeightCenter' style={{opacity:state === condition ? 1 : 0.4}}>
			<div className='flexCenter' style={{width:check_wide+20}}>
				<div className='flexCenter' style={{width:check_wide+2,height:check_wide+2,borderRadius:4,border:'1px solid'}}>
					{state === condition
						? <img src={check} alt='check' style={{width:check_wide}} />
						: null
					}
				</div>
			</div>
			<div>{text}</div>
		</div>
	)
}

export default CheckBox