import React from 'react'

// import mobile from '../../Images/mobile_screenshot.jpg'
// import code_example from '../../Images/code_example.png'
// import comp_example1 from '../../Images/comp_example1.jpeg'
import tech_example_1 from '../../Images/tech_example_1.jpeg'

// const mobile_height = 340
const height_multiply = 0.5

const ProjectDisplayContainer = ({data,width}) => {

	const height = width*height_multiply

	function size_container(component,position) {
		const className = 'overflow ' + position
		return <div style={{height:height,width:width}} className={className}>{component}</div>
	}

	return(
		<div className='fadeIn hoverOpacityDownx pointer relative borderRadius overflow ggl_border' style={{height:height,width:width}}>

			{size_container(<img src={tech_example_1} alt='backgroundImage' style={{width:width,filter:'blur(4px)'}} />,'absolute flexCenter')}
			{size_container(<div className='fullHeight fullWidth' style={{backgroundImage:'linear-gradient(to right,rgba(0,0,0,0.8),rgba(0,0,0,0))'}}></div>,'absolute pointerNone')}
			{/*{size_container(<div className='fullHeight fullWidth' style={{backgroundImage:'linear-gradient(145deg,rgba(30,0,70,1),rgba(0,0,0,1))'}}></div>,'absolute pointerNone')}*/}
			{/*{size_container(<div className='fullHeight fullWidth' style={{backgroundImage:'linear-gradient(145deg,rgba(50,20,90,1),rgba(40,50,70,1))'}}></div>,'absolute pointerNone')}*/}
			{/*{size_container(<div className='fullHeight fullWidth' style={{backgroundImage:'linear-gradient(145deg,rgba(50,20,90,1),rgba(40,50,60,1))'}}></div>,'absolute pointerNone')}*/}
			{/*{size_container(
				<div className='fullWidth fullHeight opacityUpFromZero'>
					<img src={code_example} alt='desktop' className='fullHeight' style={{opacity:0.1}} />
				</div>
			,'absolute pointerNonex')}*/}
			{size_container(
				<div className='flexRight fullWidth fullHeight'>
					<div style={{height:(height*0.84),width:(height*0.84)*1.312,marginRight:height*0.26,marginTop:10,borderRadius:8,
							boxShadow:'0px 0px 12px rgba(0,0,0,0.5)',background:'rgb(40,40,40)',border:'3px solid rgb(40,40,40)'
							}} className='flexCenter absolute overflow'>
						<img src={data['desktop']} alt='desktop' className='fullWidth' />
					</div>
				</div>
			,'absolute pointerNone')}
			{size_container(
				<div style={{backgroundImage:'linear-gradient(20deg,rgba(0,0,0,0.7),rgba(0,0,0,0))',height:height}} className='fullWidth fullHeight flexBottom'>
					<div style={{width:width*0.7,padding:height*0.05}} className='textShadowx'>
						<div className='ggl_text_main_secondary ggl_text_main_color uppercase bold' style={{textShadow:'0px 0px 20px black'}}>{data['heading']}</div>
						<div className='space_height_10'></div>
						<div className='ggl_text_smaller ggl_text_main_color'>{data['subheading']}</div>
					</div>
				</div>
			,'absolute flexBottom pointerNone')}

			{size_container(
				<div className='flexRight flexCenterx fullWidth fullHeight'>
					<div style={{width:(height*0.88)*0.565*0.82 + 1,height:(height*0.88),marginTop:(height*0.88)*0.09,
							borderRadius:(height*0.88)*0.05,
							boxShadow:'0px 0px 12px rgba(0,0,0,0.5)',background:'rgb(40,40,40)',border:'4px solid rgb(40,40,40)'
							}} className='flexCenter relative overflow'>
						<img src={data['mobile']} alt='mobile' className='fullWidth' />
					</div>
					<div style={{width:(height*0.88)*0.02}}></div>
				</div>
			,'relative pointerNone')}

		</div>
	)
}

export default ProjectDisplayContainer