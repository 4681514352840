import React from 'react'

import EducationDisplayComponent from '../Education/EducationDisplayComponent'
import EducationData from '../Data/EducationData'

// const education_list = [
// 	{link:'https://udemy-certificate.s3.amazonaws.com/image/UC-6cf1e138-2ed9-43cc-b76a-82c58a69b314.jpg?v=1723931928000',name:'TensorFlow for Deep Learning Bootcamp',year:'2024'},
// 	{link:'https://udemy-certificate.s3.amazonaws.com/image/UC-bbcabe5d-5565-4cb5-939d-af4b599a3a92.jpg?v=1633583360000',name:'Natural Language Processing: NPL With Transformers in Python',year:'2021'},
// 	{link:'https://udemy-certificate.s3.amazonaws.com/image/UC-3c7f8856-07bf-4f60-a0bf-f8be575ebc2d.jpg?v=1618044059000',name:'Complete Python Developer in 2021: Zero to Mastery',year:'2021'},
// 	{link:'https://udemy-certificate.s3.amazonaws.com/image/UC-b4ab6812-98bf-4776-ad57-37e9b16368ab.jpg?v=1619691479000',name:'Complete Machine Learning & Data Science Bootcamp 2021',year:'2021'},
// 	{link:'https://udemy-certificate.s3.amazonaws.com/image/UC-LDLX4FW4.jpg?v=1569933538000',name:'Machine Learning in Javascript with Tensorflow.js',year:'2019'},
// 	{link:'https://udemy-certificate.s3.amazonaws.com/image/UC-PIMJ8MAU.jpg?v=1533471881000',name:'The Complete Web Developer in 2018: Zero to Mastery',year:'2018'},
// 	{link:'https://udemy-certificate.s3.amazonaws.com/image/UC-5Y8QI8T5.jpg?v=1521070850000',name:'Algorithmic Trading In Forex: Create Your First Forex Robot',year:'2018'},
	
// ]

const Education = ({dimensions,route}) => {

	// function certificateClicked(e) {
	// 	window.open(e, '_blank', 'noopener,noreferrer')
	// }

	let width = dimensions.width * 0.8
	if(width < 600) width = dimensions.width

	function educationDisplay() {
		let arr = []
		for(let i = 0; i < EducationData.length; i++){
			arr.push(
				<div key={i} style={{marginBottom:20}}><EducationDisplayComponent data={EducationData[i]} width={width} /></div>
			)
		}
		return arr
	}
	return(
		<div>
			<div>Education</div>
			<div className='flexCenter'>
				<div>{educationDisplay()}</div>
			</div>
		</div>
	)
}

export default Education