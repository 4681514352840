import React from 'react'

const height_multiply = 0.5

const MusicDisplayContainer = ({data,width}) => {


	function itemClicked() {
		window.open(data['link'], "_blank")
	}

	const height = width*height_multiply

	function size_container(component,position) {
		const className = 'overflow ' + position
		return <div style={{height:height,width:width}} className={className}>{component}</div>
	}

	return(
		<div className='fadeIn hoverOpacityDownx pointer relative borderRadius overflow ggl_border' style={{height:height,width:width}} onClick={itemClicked}>

			{size_container(<div className='flexCenter fullWidth fullHeight'><img src={data['background']} alt='backgroundImage' style={{width:width}} /></div>,'absolute')}
			{size_container(<div className='fullHeight fullWidth' style={{backgroundImage:'linear-gradient(145deg,rgba(0,0,0,0),rgba(0,0,0,0.5))'}}></div>,'absolute pointerNone')}
			{size_container(
				<div style={{
						backgroundImage:'linear-gradient(0deg,rgba(0,0,0,0.7),rgba(0,0,0,0))',
						height:height*0.6}} className='fullWidth fullHeight flexBottom'>
					<div style={{width:width*0.8,padding:height*0.05}} className='textShadowx'>
						{/*<div className='bold text_bigger uppercase whiteColor' style={{textShadow:'0px 0px 20px black'}}>{data['artist']}</div>
						<div style={{marginTop:10}} className='text_heading whiteColor'>{data['name']}</div>*/}

						<div className='ggl_text_main_secondary ggl_text_main_color uppercase bold' style={{textShadow:'0px 0px 20px black'}}>{data['artist']}</div>
						<div className='space_height_10'></div>
						<div className='ggl_text_smaller ggl_text_main_color'>{data['name']}</div>
					</div>
				</div>
			,'absolute flexBottom pointerNone')}

			{size_container(
				<div className='flexRight flexCenterx fullWidth fullHeight opacityUpFromZerox'>
					<div style={{width:(height*0.84),height:(height*0.84),marginTop:(height*0.08),borderRadius:4,boxShadow:'0px 0px 12px rgba(0,0,0,0.5)',background:'black'}} className='flexCenter relative overflow'>
						<img src={data['album_art']} alt='album_art' className='fullWidth' />
					</div>
					<div style={{width:(height*0.08)}}></div>
				</div>
			,'relative pointer')}

		</div>
	)
}

export default MusicDisplayContainer