import React from 'react'

const About = ({dimensions}) => {
	return(
		<div>
			about
		</div>
	)
}

export default About