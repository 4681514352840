import React from 'react'

import spotify from '../../Images/company_icons/spotify.png'
import youtube from '../../Images/company_icons/youtube.png'
import appleMusic from '../../Images/company_icons/appleMusic.png'
import blender from '../../Images/company_icons/blender.png'
import python from '../../Images/company_icons/python.svg'
import react from '../../Images/company_icons/react_clear.png'
import redux from '../../Images/company_icons/redux.png'
import tensorflow from '../../Images/company_icons/tensorflow.png'
import vectorworks from '../../Images/company_icons/vw.png'
import node from '../../Images/company_icons/node.png'
import pytorch from '../../Images/company_icons/pytorch.png'

const example_data = [
	[
		{
			'name': 'react',
			'src': react,
			'size': 0.66,
		},
		{
			'name': 'redux',
			'src': redux,
			'size': 0.6,
		},
		{
			'name': 'python',
			'src': python,
			'size': 0.7,
		},
		{
			'name': 'tensorflow',
			'src': tensorflow,
			'size': 0.7,
		},
		{
			'name': 'node',
			'src': node,
			'size': 0.7,
		},
		{
			'name': 'pytorch',
			'src': pytorch,
			'size': 0.7,
		},
	],
	[
		{
			'name': 'spotify',
			'src': spotify,
			'size': 0.7,
		},
		{
			'name': 'youtube',
			'src': youtube,
			'size': 0.7,
		},
		{
			'name': 'appleMusic',
			'src': appleMusic,
			'size': 0.6,
		},
	],
	[
		{
			'name': 'blender',
			'src': blender,
			'size': 0.6,
		},
		{
			'name': 'vectorworks',
			'src': vectorworks,
			'size': 0.86,
		},
	],
]

const ProgramCircleDisplayer = ({width,dimensions,type}) => {


	let devision = 12
	if(dimensions.width > 600) devision = 13
	if(dimensions.width > 700) devision = 14
	if(dimensions.width > 1000) devision = 15
	if(dimensions.width > 1200) devision = 16
	if(dimensions.width > 1400) devision = 17
	let wide = dimensions.width / devision + (10/(devision-2))

	let arr = []
	for(let i = 0; i < example_data[type['current']].length; i++){
		arr.push(
			<div key={i} style={{height:wide,width:wide,borderRadius:wide,background:'rgb(40,40,40)',marginRight:10,boxShadow:'0px 0px 14px rgba(0,0,0,0.5)'}} className='flexCenter fadeIn'>
				<img src={example_data[type['current']][i]['src']} alt={`${example_data[type['current']][i]['name']}`} style={{height:wide*example_data[type['current']][i]['size']}} />
			</div>
		)
	}
	return(
		<div className='flexLeft'>{arr}</div>
	)
}

export default ProgramCircleDisplayer