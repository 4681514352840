// src/reducers/index.js
import { combineReducers } from 'redux';

// Reducers
const initialState = {
	dimensions: {width:0,height:0},
	mobile: false,
	play: false,
	selected_track: {},
	audio_time: 0,
	audio_seek: null,
	projectSlider: {prev:0,current:0,count:11},
	modal: '',
};

const dimensionReducer = (state=initialState, action) => {
	switch (action.type){
		case 'UPDATE_DIMENSIONS':
			return {...state, dimensions: action.payload}
		default:
			return state
	}
}
const mobileReducer = (state=initialState, action) => {
	switch (action.type){
		case 'CHANGE_MOBILE':
			return {...state, mobile: action.payload}
		default:
			return state
	}
}

const handlePlayback = (state=initialState, action) => {
	switch (action.type){
		case 'PLAY_CLICKED':
			return {...state, play: true}
		case 'PAUSE_CLICKED':
			return {...state, play: false}
		case 'LOAD_AUDIO_FILE_INITIATE':
			return {...state, selected_track: action.payload, play: false}
		case 'SET_AUDIO_TIME':
			return {...state, audio_time: action.payload, audio_seek: null}
		case 'SEEK_AUDIO_TIME':
			return {...state, audio_seek: action.payload}
		default:
			return state
	}
}

const projectSlider = (state=initialState, action) => {
	switch (action.type){
		case 'PROJECT_SLIDER':
			return {...state, projectSlider: action.payload}
		default:
			return state
	}
}

const modal = (state=initialState, action) => {
	switch (action.type){
		case 'MODAL_CLICKED':
			return {...state, modal: action.payload}
		default:
			return state
	}
}

// Combine Reducers
const rootReducer = combineReducers({
	dimensions: dimensionReducer,
	mobile: mobileReducer,
	handlePlayback: handlePlayback,
	projectSlider: projectSlider,
	modal: modal,
});

export default rootReducer;