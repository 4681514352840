import React, { useState } from 'react'
import { doc, setDoc } from 'firebase/firestore'
import { db } from '../../firebase'

import TextInput from '../MiniComponents/TextInput'
import CheckBox from '../MiniComponents/CheckBox'

const ContactFormRendering = ({dimensions}) => {
	const [ name, setName ] = useState('')
	const [ email, setEmail ] = useState('')
	// const [ subject, setSubject ] = useState('')
	const [ message, setMessage ] = useState('')
	const [ checkBox, setCheckBox ] = useState('')
	const [ messageSent, setMessageSent ] = useState(false)

	function checkClicked(e) {
		if(e === checkBox){
			setCheckBox('')
		} else {
			setCheckBox(e)
		}
	}

	async function sendMessageClicked() {
		const id = String(Math.round(Math.random()*10000000000))
		let object = {
			'id': id,
			'name': name,
			'email': email,
			'subject': '',
			'message': message,
			'date': String(new Date()),
			'read': false,
			'project_type': checkBox,
		}
		await setDoc(doc(db, 'messages', id), object)
		// message successfully sent...
		setMessageSent(true)
	}



	return(
		<div className='ggl_text_main_secondary ggl_text_secondary_color'>
			{messageSent === true
				? <div className='absolute flexCenter textCenter' style={{width:dimensions.width,height:600}}>
					<div>
						<div className='bold ggl_text_main_color'>Thank you for your message</div>
						<div className='space_height_10'></div>
						<div className='ggl_text_smaller'>We will be in touch as soon as possible</div>
					</div>
				</div>
				: null
			}
			<div style={{opacity:messageSent === true ? 0.3 : 1,filter:messageSent === true ? 'blur(6px)' : ''}}>
				<div className='space_height_40'></div>
				<div className='paragraphMarginBottom'>
					<TextInput placeholder='Your name*' value={name} onChange={setName} input_type='text'
							// id={String(Math.round(Math.random()*10000000))}
							label={'Name'}
							component_type='input' enterPressed={()=>{}} width={dimensions.width} height={34} />
				</div>
				<div className='paragraphMarginBottom'>
					<TextInput placeholder='Your email*' value={email} onChange={setEmail} input_type='email'
							// id={String(Math.round(Math.random()*10000000))}
							label={'Email'}
							component_type='input' enterPressed={()=>{}} width={dimensions.width} height={34} />
				</div>
				{/*<div className='paragraphMarginBottom'>
					<TextInput placeholder='Possible oppotunity...' value={subject} onChange={setSubject} input_type='text'
							// id={String(Math.round(Math.random()*10000000))}
							label={'Subject'}
							component_type='input' enterPressed={()=>{}} width={dimensions.width} height={34} />
				</div>*/}

				<div className='space_height_10'></div>

				<div className='labelMarginBottom ggl_text_smaller overflow relative'>
					<div className='textAnimateIn absolute' style={{width:dimensions.width}}>Project Type</div>
					<div className='noOpacity'>Project Type</div>
				</div>
				<div className='pointer ggl_text_smaller bold' onClick={()=>{checkClicked('interior')}}>
					<CheckBox state={checkBox} condition={'interior'} text={'Interior Render'} dimensions={dimensions} />
				</div>
				<div className='space_height_10'></div>
				<div className='pointer ggl_text_smaller bold' onClick={()=>{checkClicked('exterior')}}>
					<CheckBox state={checkBox} condition={'exterior'} text={'Exterior Render'} dimensions={dimensions} />
				</div>
				<div className='space_height_10'></div>
				<div className='pointer ggl_text_smaller bold' onClick={()=>{checkClicked('floorplan')}}>
					<CheckBox state={checkBox} condition={'floorplan'} text={'3D Floor Plan'} dimensions={dimensions} />
				</div>
				<div className='space_height_10'></div>
				<div className='pointer ggl_text_smaller bold' onClick={()=>{checkClicked('animated')}}>
					<CheckBox state={checkBox} condition={'animated'} text={'Animated Render'} dimensions={dimensions} />
				</div>
				<div className='space_height_20'></div>

				<div className='paragraphMarginBottom'>
					<TextInput placeholder='Information about your project*' value={message} onChange={setMessage} input_type='text'
							// id={String(Math.round(Math.random()*10000000))}
							label={'Project brief'}
							component_type='textArea' enterPressed={()=>{}} width={dimensions.width} height={244} />
				</div>

				

				<div className='space_height_20'></div>
				<div className='fadeInslow'>
				{name === '' || email === '' || !email.includes('@') || message === ''
					? <div className='button ggl_text_smaller flexCenter actionColor whiteColor pointerNone halfOpacity'>Begin project</div>
					: <div onClick={sendMessageClicked} className='button ggl_text_smaller flexCenter actionColor whiteColor pointer'>Begin project</div>
				}
				</div>
			</div>
		</div>
	)
}

export default ContactFormRendering