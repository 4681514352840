import React from 'react'
import { connect } from 'react-redux'

import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import Home from './Home'
import About from './About'
import Admin from './Admin'
import Contact from './Contact'
import Projects from './Projects'
import Education from './Education'
import AudioPlaybackFunction from '../AudioPlayers/AudioPlaybackFunction'
import BottomPlaybackBar from '../AudioPlayers/BottomPlaybackBar'
import Modal from '../MiniComponents/Modal'
import RenderRoute from './RenderRoute'
import RenderRoute2 from './RenderRoute2'

const MainRoute = ({route,navigate, dimensions,modal,modelClicked}) => {
	let footer_height = 300
	let body_height = dimensions.height - footer_height
	const navHeight = 50



	let default_width = 1400
	if(dimensions.width < 1400 + 120) default_width = dimensions.width - 120
	if(dimensions.width < 760 + 80) default_width = dimensions.width - 80
	if(dimensions.width < 600 + 40) default_width = dimensions.width - 40
	if(dimensions.width < 500) default_width = dimensions.width - 10

	function routeDisplay() {
		if(route === '') return <Home dimensions={dimensions} default_width={default_width} navigate={navigate} />
		if(route === 'about') return <About />
		if(route.includes('projects')) return <Projects dimensions={dimensions} route={route} default_width={default_width} />
		if(route === 'contact') return <Contact dimensions={dimensions} />
		if(route === 'admin') return <Admin />
		if(route === 'education') return <Education dimensions={dimensions} route={route} />
		if(route === 'rendering') return <RenderRoute dimensions={dimensions} route={route} default_width={default_width} navigate={navigate} />
		if(route === 'rendering2') return <RenderRoute2 dimensions={dimensions} route={route} default_width={default_width} navigate={navigate} />
	}
	if(dimensions.height === 0) return null
	let should_have_nav = true
	if(route === 'rendering' || route === 'rendering2') should_have_nav = false
	return(
		<div
				className='blackBackground poppins-regular'
				// style={{background:'rgb(16,14,18)'}}
				>
			{should_have_nav ? <Navbar route={route} height={navHeight} dimensions={dimensions} navigate={navigate} /> : null}

			<div style={{minHeight:body_height,zIndex:0}} className='relative'>
				{should_have_nav ? <div style={{height:navHeight}}></div> : null}
				{routeDisplay()}
			</div>


			<AudioPlaybackFunction />

			<Footer height={footer_height} navigate={navigate} />

			<BottomPlaybackBar />

			<Modal />
		</div>
	)
}

// export default MainRoute

const mapStateToProps = (state) => ({
	dimensions: state.dimensions.dimensions,
	modal: state.modal.modal,
})
const mapDispatchToProps = (dispatch) => ({
	// increment: () => dispatch({type: 'INCREMENT' }),
	// decrement: () => dispatch({type: 'DECREMENT' }),
	modelClicked: (e) => dispatch({type: 'MODAL_CLICKED', payload: e }),
})

export default connect(mapStateToProps, mapDispatchToProps)(MainRoute)