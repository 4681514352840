import React from 'react'

const MessageDisplayMini = ({message}) => {
	return(
		<div className='flexHeightCenter' style={{height:48,border:'1px solid red'}}>
			{message['read'] === false
				? <div style={{width:40}} className='flexCenter'>
					<div style={{height:10,width:10,borderRadius:'50%',background:'rgb(100,150,190)'}}></div>
				</div>
				: <div style={{width:40}}></div>
			}
			<div>
				<div className='ggl_text_smaller bold'>{message['name']}</div>
				<div className='ggl_text_smaller ggl_text_secondary_color'>{message['project_type'] !== undefined ? message['project_type'] : message['subject'] }</div>
			</div>
		</div>
	)
}

export default MessageDisplayMini