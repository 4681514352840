import React, { useState, useEffect } from 'react'
import { doc, getDoc, collection, getDocs, updateDoc } from 'firebase/firestore'
import FadeLoader from 'react-spinners/FadeLoader'
import { db } from '../../firebase'

import TextInput from '../MiniComponents/TextInput'
import MessageDisplayMini from '../Contact/MessageDisplayMini'

const Admin = () => {
	const [ permission, setPermission ] = useState(false)
	const [ password, setPassword ] = useState('')
	const [ messages, setMessages ] = useState([])
	const [ message, setMessage ] = useState('')
	const [ loading_message, setLoading_message ] = useState(false)

	useEffect(()=>{
		async function getMessages() {
			// get the emails from firebase
			let arr = []
			const querySnapshot = await getDocs(collection(db, 'messages'))
			querySnapshot.forEach((doc) => {
				arr.push(doc.data())
			})
			arr.sort(function(a,b){
				return new Date(b.date) - new Date(a.date)
			});
			setMessages(arr)
		}
		if(permission === true){
			getMessages()
		}
	},[permission])

	async function checkPassword() {
		if(password === '') return null
		const docRef = doc(db, 'admin', password);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			setPermission(true)
		}
	}

	async function messageClicked(e) {
		setLoading_message(true)
		if(e.read === false){
			const dbRef = doc(db, 'messages', e.id)
			await updateDoc(dbRef, {'read': true})

			// update the local variable
			let arr = []
			for(let i = 0; i < messages.length; i++){
				if(e['id'] === messages[i]['id']){
					let updated = e
					updated.read = true
					arr.push(updated)
				} else {
					arr.push(messages[i])
				}
			}
			setMessage(arr)
		}
		setMessage(e)
		setLoading_message(false)
	}


	function messageList() {
		let arr = []
		for(let i = 0; i < messages.length; i++){
			arr.push(
				<div key={i} onClick={()=>{messageClicked(messages[i])}}>
					<MessageDisplayMini message={messages[i]} />
				</div>
			)
		}
		return arr
	}
	function messageDisplay() {
		if(message === '') return null
		if(loading_message === true){
			return(
				<div className='flexCenter' style={{height:140}}>
					<FadeLoader height={12} width={4} radius={2} margin={2} color={'red'} loading={true} cssOverride={''} size={150} aria-label='Loading Spinner' data-testid='loader' />
				</div>
			)
		}
		return(
			<div>
				<div>{message['name']} ({message['email']})</div>
				<div>{message['project_type']}</div>
				<div>{message['message']}</div>
				<div>{message['date']}</div>
			</div>
		)
	}

	if(permission === false){
		return(
			<div>
				<TextInput placeholder='password...' value={password} onChange={setPassword} input_type='password' id={String(Math.round(Math.random()*10000000))} label={'Password'} component_type='input' enterPressed={checkPassword} width={200} height={30} />
				<div onClick={checkPassword}>Button</div>
			</div>
		)
	}
	return(
		<div className='ggl_text_smaller ggl_text_main_color'>
			{messageList()}
			{messageDisplay()}
		</div>
	)
}

export default Admin