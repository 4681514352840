import React, { useState, useEffect } from 'react'

import HomePageSectionRender from '../Rendering/HomePageSectionRender2'
import RenderPricing from '../Pricing/RenderPricing'
import Contact from './Contact'
import RenderSliderImage from '../Rendering/RenderSliderImage'
import RenderProject from '../Data/RenderProject'
// import Testimonials from '../MiniComponents/Testimonials'
// import ContactFormRendering from '../Contact/ContactFormRendering'

// import Check from '../../Images/check.svg'
// import photorendism_stages from '../../Images/renders/photorendism_stages_1.2.mp4'

// const serviceList = [
// 	{
// 		'name': 'Interior Rendering',
// 		'info': 'Bring your indoor spaces to life with detailed and immersive visuals',
// 		'img': RenderProject['dark_kitchen']['src'],
// 		'longest_text': 'Interactive, immersive experiences for presentations with clients',
// 	},
// 	{
// 		'name': 'Exterior Rendering',
// 		'info': 'Showcase how buildings sit in environments',
// 		'img': RenderProject['exterior_render_1']['src'],
// 		'longest_text': 'Interactive, immersive experiences for presentations with clients',
// 	},
// 	{
// 		'name': 'Section Renders',
// 		'info': 'Interactive, immersive experiences for presentations with clients',
// 		'img': RenderProject['section_render']['src'],
// 		// 'vid': RenderProject['section_render']['video'],
// 		'longest_text': 'Interactive, immersive experiences for presentations with clients',
// 	},
// 	// {
// 	// 	'name': '3D Floor Plans',
// 	// 	'info': 'Help clients visualize layout and spatial relationships',
// 	// 	'img': RenderProject['da_col_mitta']['src'],
// 	// 	'longest_text': 'Interactive, immersive experiences for presentations with clients',
// 	// },
// 	{
// 		'name': 'Animated Renders',
// 		'info': 'Interactive, immersive experiences for presentations with clients',
// 		'img': RenderProject['a_bed2']['src'],
// 		'vid': RenderProject['a_bed2']['video'],
// 		'longest_text': 'Interactive, immersive experiences for presentations with clients',
// 	},
// ]

const carouselData = [RenderProject['exterior_render_1']['src'],RenderProject['dark_kitchen']['src'],RenderProject['sydney_kitchen']['src'],]

const height_multiply = 0.5

const RenderRoute2 = ({dimensions,route,default_width,navigate}) => {
	const [ slide, setSlide ] = useState(0)

	const carouselInfinteScroll = () => {
		if(slide === carouselData.length-1) return setSlide(0)
		return setSlide(slide+1)
	}

	useEffect(()=>{
		const interval = setInterval(()=>{carouselInfinteScroll()}, 4000)
		return () => clearInterval(interval)
	})



	// function includedItem(bold,text,width) {
	// 	let left_width = 60
	// 	if(width < 700) left_width = 40
	// 	return(
	// 		<div className='flexHeightCenter fullWidth ggl_text_smaller fadeInslow ggl_text_secondary_color'>
	// 			<div style={{width:left_width}} className='flexCenter'>
	// 				<div className='flexCenter' style={{height:left_width*0.44,width:left_width*0.44,borderRadius:'50%',border:'1px solid white'}}>
	// 					<img src={Check} alt='check' style={{width:left_width*0.32}} />
	// 				</div>
	// 			</div>
	// 			<div style={{width:width-left_width}}><b>{bold}</b>{text}</div>
	// 		</div>
	// 	)
	// }
	// function services(width) {
	// 	let arr = []
	// 	let devision = 4
	// 	if(width < 1400) devision = 3
	// 	if(width < 1100) devision = 2
	// 	if(width < 640) devision = 1
	// 	for(let i = 0; i < serviceList.length; i++){
	// 		arr.push(
	// 			<div key={i}>
	// 			<div style={{width:width/devision}} className='flexCenter fadeInslow'>
	// 				<div style={{width:width/devision - 20}} className=' ggl_border borderRadius ggl_text_main_color ggl_text_smaller textCenter overflow'>
	// 					<div style={{width:width/devision - 20,height:(width/devision-20)*0.6}} className='overflow flexCenter'>
	// 						{serviceList[i]['vid'] !== undefined
	// 							? <video loop autoPlay height={(width/devision-20)*0.6}>
	// 								<source src={serviceList[i]['vid']} type="video/mp4" />
	// 							</video>
	// 							: <img src={serviceList[i]['img']} alt='' className='fullHeight' />
	// 						}
	// 					</div>
	// 					{/*<div className='ggl_text_secondary_color' style={{borderTop:'1px solid'}}></div>*/}
	// 					<div style={{padding:20}} className='ggl_box_background'>
	// 						<div>{serviceList[i]['name']}</div>
	// 						<div className='space_height_10'></div>
	// 						<div className='ggl_text_secondary_color overflow relative' style={{width:width/devision - 20 - 40}}>
	// 							<div className='textAnimateIn2 absolute' style={{width:width/devision - 20 - 40}}>{serviceList[i]['info']}</div>
	// 							<div className='noOpacity'>{serviceList[i]['longest_text']}</div>
	// 						</div>
	// 						{/*<div className='ggl_text_secondary_color'>{serviceList[i]['info']}</div>*/}
	// 					</div>
	// 				</div>
	// 			</div>
	// 			<div className='space_height_20'></div>
	// 			</div>
	// 		)
	// 	}
	// 	return <div className='flexCenter textAnimateInBig' style={{flexWrap:'wrap'}}>{arr}</div>
	// }

	let height = default_width*height_multiply
	if(default_width < 850) height = default_width*0.6
	if(default_width < 600) height = default_width*0.7
	if(default_width < 450) height = default_width*0.8
	// let padding = 40
	// if(default_width < 1000) padding = 20
	let contact_width = 700
	if(dimensions.width - 40 < contact_width) contact_width = dimensions.width - 40
	// let process_multiplier = 0.5
	// if(default_width < 1100) process_multiplier = 0.6
	// if(default_width < 1000) process_multiplier = 0.7
	return(
		<div>


			<div className='relative flexCenter fadeInslow pointerNone'
					// style={{background:'white'}}
				><HomePageSectionRender width={dimensions.width} fullDisplay={false} navigate={navigate} text_display={false} /></div>

			<div className='flexCenter textCenter radialColorBackground homepage_hero_heightx'>
				<div style={{width:default_width-40}}>
					{/*<div className='space_height_60'></div>*/}
					<div className='space_height_60'></div>
					<div className='ggl_text_hero ggl_text_main_color overflow relative' style={{}}>
						<div className='textAnimateIn absolute textCenter' style={{width:default_width-40}}>PhotoRender <b className='textGradientArch noBold'>Architectural</b> Rendering</div>
						<div className='noOpacity'>PhotoRender <b className='textGradientArch'>Architectural</b> Rendering</div>
					</div>
					<div className='space_height_40'></div>
					{/*<div className='flexCenter'><div className='ggl_text_main_secondary ggl_text_secondary_color overflow relative' style={{width:default_width-80}}>
						<div className='textAnimateIn2 absolute textCenter' style={{width:default_width-80}}>Bring your vision to life and leave a lasting impression on your clients</div>
						<div className='noOpacity'>Bring your vision to life and leave a lasting impression on your clients</div>
					</div></div>*/}

					<div className='flexCenter'><div className='ggl_text_main_secondary ggl_text_secondary_color overflow relative' style={{width:default_width-80}}>
						<div className='textAnimateIn2 absolute textCenter' style={{width:default_width-80}}>Elevate your projects with realistic renders that let clients see—and feel—the vision</div>
						<div className='noOpacity'>Elevate your projects with realistic renders that let clients see—and feel—the vision</div>
					</div></div>

					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
					{/*<div className='space_height_60'></div>*/}
				</div>
			</div>

			{/*<div className='flexCenter fadeIn'><ChatSquareHero width={default_width} height={height} padding={padding} navigate={navigate} /></div>

			<div className='space_height_60'></div>
			<div className='space_height_60'></div>
			<div className='space_height_60'></div>*/}


			<div className='flexCenter'><RenderSliderImage width={default_width} height={height} data={RenderProject['dark_kitchen']} id={'24g35uw5hwghadfgvdf'} type={'left'}
					text={{heading:'Interior',text:'Visualize your space with precision and realism'}} /></div>
			<div className='space_height_60'></div>

			<div className='flexCenter'><RenderSliderImage width={default_width} height={height} data={RenderProject['exterior_render_1']} id={'wiehoiweh2980hg29'} type={'right'}
					text={{heading:'Exterior',text:'See your spaces in stunning detail before construction begins'}} /></div>
			<div className='space_height_60'></div>
			
			<div className='flexCenter'><RenderSliderImage width={default_width} height={height} data={RenderProject['sydney_kitchen']} id={'hhg384g34g3hj6wsv'} type={'slider'}
					text={{heading:'Exterior',text:'Visualize your space with precision and realism'}} /></div>
			<div className='space_height_60'></div>


			{/*<div className='flexCenter fadeIn'><RenderAllProjectDisplay width={default_width} height={height} padding={padding} navigate={navigate} /></div>*/}


			<div className='radialColorBackground'>
				<div className='space_height_60'></div>
				<div className='space_height_60'></div>
				<div className='space_height_60'></div>

				<div className='flexCenter textCenter'><div style={{width:default_width}}>
					<div className='ggl_text_heading ggl_text_main_color boldx'>My work</div>
					{/*<div className='ggl_text_main_secondary ggl_text_secondary_color'>Here is some of my work for you to view</div>*/}
				</div></div>
				<div className='space_height_20'></div>

				<div className='flexCenter'><div style={{width:default_width}}><HomePageSectionRender width={default_width} fullDisplay={true} /></div></div>
			</div>

			<div className='radialColorBackground'>
				<div className='flexCenter'>
					<div className='ggl_text_heading ggl_text_main_color overflow relative' style={{width:default_width}}>
						<div className='textAnimateIn absolute textCenter' style={{width:default_width-40}}>Prices</div>
						<div className='noOpacity'><b className='textGradientx'>Prices</b> </div>
					</div>
				</div>
				<div className='space_height_20'></div>
				<div className='flexCenter'>
					<RenderPricing width={default_width} />
				</div>
			</div>

			<div className='space_height_60'></div>

			<Contact dimensions={dimensions} />

			<div className='space_height_60'></div>
			<div className='space_height_60'></div>



		</div>
	)
}

export default RenderRoute2