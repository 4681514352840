import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import RenderProject from '../Data/RenderProject'

import chevron from '../../Images/chevron-right-white.svg'
import cross from '../../Images/x-white.svg'

const Modal = ({dimensions,modal,modelClicked}) => {

	// Modal stuff
		const node = useRef();
		useEffect(()=>{
			function handleClick(e) {
				if(node.current) {
					if(!node.current.contains(e.target)){
						modelClicked('')
					}
				}
			}
			document.addEventListener('mousedown', handleClick, false);
			return () => {
				document.removeEventListener('mousedown', handleClick, false);
			}
		},[modelClicked]);


	function previousRenderClicked() {
		let last_key = Object.keys(RenderProject)[Object.keys(RenderProject).length-1]
		let last_select = RenderProject[last_key]
		let selected_key = ''
		for(const [key, val] of Object.entries(RenderProject)){
			if(key === modal['data']['key']){
				selected_key = last_select
			}
			last_select = val
			last_key = key
		}
		modelClicked({'type':'render','data':selected_key})
	}
	function nextRenderClicked() {
		let last_key = Object.keys(RenderProject)[Object.keys(RenderProject).length-1]
		let selected_key = RenderProject[last_key]
		for(const [key, val] of Object.entries(RenderProject)){
			if(last_key === modal['data']['key']){
				selected_key = val
			}
			last_key = key
		}
		modelClicked({'type':'render','data':selected_key})
	}

	function renderDisplay() {
		let width = dimensions.width
		let height = dimensions.height
		let x_unit = ''
		let y_unit = ''
		if(modal['data']['type'] === 'square'){
			if(width < height){
				height = width
			} else {
				width = height
			}
			x_unit = width
			y_unit = width
		} else if(modal['data']['type'] === 'high'){
			if(width*1.5 < height){
				x_unit = width
				y_unit = width * 1.5
			} else {
				x_unit = height * 0.66
				y_unit = height
			}
		} else if(modal['data']['type'] === 'wide'){
			if(width > height*1.5){
				x_unit = height * 1.5
				y_unit = height
			} else {
				x_unit = width
				y_unit = width * 0.66
			}
		}
		x_unit-=80
		y_unit-=80
		return(
			<div style={{width:x_unit,height:y_unit}} className='flexLeft overflow'>
				{/*<div><div className='absolute flexCenter' style={{height:y_unit}}>
					<div onClick={previousRenderClicked} className='prevNextArrowCircle pointer flexCenter pointerAuto'>
						<img src={chevron} alt='chevron' style={{width:30}} />
					</div>
				</div></div>*/}
				<div style={{width:x_unit,height:y_unit}} className='flexCenter'>{modal['data']['type'] === 'wide'
					? <img src={modal['data']['src']} alt='render' className='fullWidth' />
					: <img src={modal['data']['src']} alt='render' className='fullHeight' />
				}</div>
				{/*<div><div className='absolute flexCenter' style={{height:y_unit}}>
					<div onClick={nextRenderClicked} className='prevNextArrowCircle pointer flexCenter pointerAuto' style={{marginLeft:-60}}>
						<img src={chevron} alt='chevron' style={{width:30,transform:'rotate(180deg)'}} />
					</div>
				</div></div>*/}

				
			</div>
		)
	}

	if(modal === '') return null
	return(
		<div className='relative' style={{zIndex:2}}>
			<div className='modalBack flexCenter'>
			{/*<div ref={node}>*/}
				<div>
					{modal['type'] === 'render' ? renderDisplay() : null}
				</div>
			</div>
			
			<div className='fixed pointerNonex flexCenter' style={{top:0,right:0,bottom:0,left:0}}>
				<div className='halfWidth fullHeight pointer hoverOpacityUp flexHeightCenter' style={{backgroundImage:'linear-gradient(to right,rgba(0,0,0,0.3),rgba(0,0,0,0))'}} onClick={previousRenderClicked}>
					<img src={chevron} alt='chevron' style={{width:40,transform:'rotate(180deg)'}} />
				</div>
				<div className='halfWidth fullHeight pointer hoverOpacityUp flexHeightCenter flexRight' style={{backgroundImage:'linear-gradient(to left,rgba(0,0,0,0.3),rgba(0,0,0,0))'}} onClick={nextRenderClicked}>
					<img src={chevron} alt='chevron' style={{width:40}} />
				</div>
			</div>
			<div className='fixed' style={{top:10,right:10,}}>
				<div onClick={()=>{modelClicked('')}} className='prevNextArrowCircle pointer flexCenter pointerAuto' style={{marginLeft:-60,background:'rgba(0,0,0,0)'}}>
					<img src={cross} alt='cross' style={{width:34}} />
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	dimensions: state.dimensions.dimensions,
	modal: state.modal.modal,
})
const mapDispatchToProps = (dispatch) => ({
	modelClicked: (e) => dispatch({type: 'MODAL_CLICKED', payload: e }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Modal)